import React, { Component } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux"
//import { Link } from "react-router-dom"

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

/* import FloatingWhatsApp from 'react-floating-whatsapp'
import WhatsappIcon from "@material-ui/icons/WhatsApp" */

import { Fab, Action } from 'react-tiny-fab';
import style from 'react-tiny-fab/dist/styles.css';


import MessengerCustomerChat from 'react-messenger-customer-chat';
import { withTranslation } from "react-i18next"
// layouts
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { Modal, Input, ModalBody } from "reactstrap"
// Import scss
import "./assets/scss/theme.scss"
import qr from "../src/assets/images/qr.png"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"
import { Container, Button } from 'react-floating-action-button'
import ReactGA from 'react-ga4';
ReactGA.initialize('G-L59SMK92W8');
// Import fackbackend Configuration file
//import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
//fakeBackend()

// Activating fake firebase
const firebaseConfig = {
  apiKey: "AIzaSyDnrzKjhTlUkzLhA9UQ4M7YPgEdFC5dVj8",
  authDomain: "meanz-3960c.firebaseapp.com",
  databaseURL: "https://meanz-3960c-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "meanz-3960c",
  storageBucket: "meanz-3960c.appspot.com",
  messagingSenderId: "872960828688",
  appId: "1:872960828688:web:08eba134443f69d81bb195",
  measurementId: "G-L59SMK92W8",
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingUrl: false,
      reservations: [],
      vehicles: [],
      subscribed: true,
      subscribemodal: true,
      user: [],
      current: null,
      
    }
    this.getLayout = this.getLayout.bind(this)
  }
  

  componentDidMount() {
    const { reservations } = this.state
    this.subscribed()
    ReactGA.send({
			hitType: "pageview",
			page: window.location.pathname,
		  });
      const script = document.createElement('script');
      script.src = "https://www.googletagmanager.com/gtag/js?id=AW-961565114";
      script.async = true;
      document.head.appendChild(script);
    // this.getVehicles()
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "ffa3c113-0fc3-466a-87ea-839dc0b8678a";
    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://s.skimresources.com/js/233964X1716622.skimlinks.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    window.scrollTo(0, 0)

  }


  subscribed = async () => {

    
      this.setState({ loading: true });
  
      // Unsubscribe previous listeners to prevent memory leaks
      if (this.unsubscribeUsers) {
        this.unsubscribeUsers();
      }
  
      this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const userId = user.uid;
          const usersCollection = firebase.firestore().collection("users")
          const userDocRef = usersCollection.doc(userId);
          //console.log(user)
          try {
            const userDoc = await userDocRef.get();
            if (userDoc.exists) {
              const userId = userDoc.data().id;
              const userUsersCollectionRef = usersCollection.doc(userId)
              
              
              this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
                let kra = snapshot.data()
                this.setState({
                user: kra,
              })
              });
  
              
  
              localStorage.setItem("authUser", JSON.stringify(user));
            } else {
              // User document not found
              localStorage.removeItem("authUser");
              
            }
          } catch (error) {
            console.error("Error fetching user document:", error);
            // Handle error
          }
        } else {
          localStorage.removeItem("authUser");
          // Clear the reservations if user is not authenticated
          
        }
      });
  
    

  }
  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  /*  togglesubscribemodal = () => {
     this.setState(prevState => ({
       subscribemodal: !prevState.subscribemodal,
     }))
   }
 
   whatsappLink() {
     const newWindow = window.open('https://wa.me/+2120607802166', '_blank', 'noopener,noreferrer')
     if (newWindow) newWindow.opener = null
   } */
  redirectToCheckout = async (userId) => {
    this.setState({ loadingUrl: true, modal: false, })

    const userRef = firebase.firestore().collection("users").doc(userId)
    await userRef.collection("checkout_sessions").add({
      /* customer: customer, */
      mode: "payment",
      price: "price_1Q0M6RGFbCpQEhRTjYkJFAJ8",
      success_url: `${window.location.origin}/dashboard`,
      cancel_url: `${window.location.origin}/dashboard`,
      allow_promotion_codes: true,
    }).then((docRef) => {
      docRef.onSnapshot(async (snap) => {
        const { url } = snap.data();
        //console.log("west lfaunction", url)
        if (url !== undefined) {
          this.setState({ loadingUrl: false })
          /* window.open(url); */
          window.location.href = url;
        }
      })
    })

    // this.toggle()
  }
  render() {

    const { vehicles, user, current } = this.state
    //console.log(user)
    const Layout = this.getLayout()
    const pathname = window.location.pathname
    var showWhatsapp = true
    authProtectedRoutes.map(function (route, idx) {

      if (route.path === pathname) {
        showWhatsapp = false
      }
    })
    publicRoutes.map(function (route, idx) {

      if (route.path === pathname) {
        showWhatsapp = false
      }
    })
    return (
      <React.Fragment>
        <Modal isOpen={this.state.loadingUrl} centered={true} size="sm" >
          <ModalBody>
            <Col>
              <div>
                <div >
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
              <br />
              <h6 className='text-center'>{this.props.t("Redirecting to checkout page ...")}</h6>
            </Col>

          </ModalBody>
        </Modal>
        <Router>
          {/* <MessengerCustomerChat
            pageId="101505825481823"
            appId="578835029756316"
          />, */}
          {/* <div className="App">
    <Fab >
          <WhatsappIcon/>
          </Fab>
  </div>
   */}

          <Modal
            isOpen={!user.subscribed && !user.validTrial && user.length !== 0}
            role="alert"
            autoFocus={true}
            data-toggle="modal"
            centered
            size="md"
          >


            <Row className="justify-content-center">
              <Col >

                <div className="p-2 mt-4 mb-0">
                  <div className="text-center">
                    <div className="avatar-md mx-auto">
                      <div className="avatar-title rounded-circle bg-light">
                        <i className="mdi mdi-cart-check h1 mt-4 text-primary"></i>
                      </div>
                    </div>
                    <div className="p-2 mt-3 mb-0">
                      <h4>{this.props.t("Your free trial has expired.")}</h4>
                      <br />
                      <p>


                        {this.props.t("Ready to take the next step?")}<br /> {this.props.t("Click the link below to purchase")}
                        <span className="text-body fw-bold">
                          {" Medios "}
                        </span>
                        {this.props.t("now.")}{" "}
                      </p>

                    </div>
                  </div>
                </div>

                <div className="mt-0 mb-4 text-center">
                  <h5 className=" text-warning  me-3 mt-4 " onClick={() => this.redirectToCheckout(user.id)} > <i className="mdi mdi-crown-outline  mdi-18px  me-1 mt-2"></i><strong>{" "}{this.props.t("Buy Now")}</strong></h5>

                </div>
              </Col>
            </Row>
          </Modal>

          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

          </Switch>
          {/* <FloatingWhatsApp 
          phoneNumber="+212607802166"
          accountName="Meanz support team"
        allowClickAway
        notification
        notificationDelay={60000} // 1 minute
        notificationSound/>, */}
          {/* {showWhatsapp?null:
        <Container className="d-print-none "  >

        <Button
          tooltip="Contact Meanz support!"
          rotate={false}
          styles={{ backgroundColor: "#25D366", color: "white" }}
          icon="fab fa-whatsapp font-size-24"
          onClick={this.whatsappLink} />
      </Container>} */}

          {/* <Fab 
            icon={<i className="fab fa-whatsapp font-size-24 " />}
            alwaysShowTitle={true}
            mainButtonStyles={{ color: "white", backgroundColor: "#25D366" }}
            onClick={this.whatsappLink}
            style={{ bottom: 25, right: 25 }}
            
          >
            
          </Fab> */}


        </Router>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

App.propTypes = {
  layout: PropTypes.object,
  t: PropTypes.any,
}

export default connect(mapStateToProps, null)(withTranslation()(App))

import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  CardFooter,
  Col,
  Button,
  Badge,
  Label,
  Row,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  CardHeader,
  CardImg, Table
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { size, map } from "lodash"
import StarRatings from "react-star-ratings"

import images from "assets/images"
import * as moment from 'moment';

class CardContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      req: null
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  render() {
    const { request } = this.props
    //console.log(request)
    var test = moment(request.pickup_date).fromNow()
    var a = moment(new Date(request.pickup_date));
    var b = moment(new Date());
    var c = moment(new Date(request.return_date))
    var diff = a.diff(b, 'minutes')
    var days = c.diff(a, "days")

    return (
      <React.Fragment>
        <Col xl="3" sm="6">
          <Card className="text-center" >

            <CardBody>

              <div className="text-end mt-0 mb-2">
                <Badge
                  className={(diff <= 0) ? "text-end font-size-10 badge-soft-danger" : (diff >= 0) ? "text-end font-size-10 badge-soft-success" : "text-end font-size-10 badge-soft-warning"}
                  color={request.status}
                  pill
                >
                  {(diff <= 0) ? "Expired" : (diff >= 0) ? test : "Urgent"}

                </Badge>
              </div>

              {!request.profile_picture ?
                (
                  <div className="avatar-sm mx-auto mb-4">
                    <span className={"avatar-title rounded-circle bg-dark-subtle text-dark font-size-16"} >
                      {request.first_name.charAt(0)}
                    </span>
                  </div>
                )
                : (
                  <div className="mb-3">
                    <img
                      className="rounded-circle avatar-md"
                      src={request.profile_picture}
                      alt=""
                    />
                  </div>
                )}

              <h5 className="font-size-15 mb-0">
                <Link to="#" className="text-dark">
                  {request.first_name + " " + request.last_name}
                </Link>

              </h5>
              <div className="text-muted float-center ">
                <StarRatings
                  rating={request.rating}
                  starRatedColor="#F1B44C"
                  starEmptyColor="#2D363F"
                  numberOfStars={5}
                  name="rating"
                  starDimension="14px"
                  starSpacing="3px"
                />
                <p className="text-muted font-size-12  ">{" ( " + request.nbr_reviews + " reviews )"}</p>

              </div>
              <hr></hr>
              <h5 className="text-muted ">{days + " Days"}</h5>
              <div>
                <p className="text-center mb-0 font-size-12">{moment(new Date(request.pickup_date)).format("DD-MM-YYYY")}<i className="mdi mdi-arrow-right ms-1" /> {moment(new Date(request.return_date)).format("DD-MM-YYYY")} </p>
              </div>
            </CardBody>
            <CardFooter className="text-center bg-transparent border-top">
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => this.toggle()}
              >
                {"View Details"}{" "}<i className="mdi mdi-arrow-right ms-1" />
              </Button>
            </CardFooter>
          </Card>
        </Col>
        <Modal
          isOpen={this.state.modal}
          size="lg"
        >
          <ModalHeader toggle={this.toggle} tag="h5">
            online booking
          </ModalHeader>
          <ModalBody>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row className='text-center'>

                      <div className="text-center mb-2">
                        <img
                          src={request.profile_picture}
                          alt=""
                          className="avatar-xl rounded-circle img-thumbnail"
                        />
                      </div>
                      <h6 className="mb-0">{request.first_name + " " + request.last_name}</h6>
                      <div className="text-muted float-center ">
                        <StarRatings
                          rating={request.rating}
                          starRatedColor="#F1B44C"
                          starEmptyColor="#2D363F"
                          numberOfStars={5}
                          name="rating"
                          starDimension="14px"
                          starSpacing="3px"
                        />
                        <p className="text-muted font-size-12  ">{" ( " + request.nbr_reviews + " reviews )"}</p>

                      </div>

                    </Row>
                    <Row className='text-start align-self-center'>
                      <Col md="4" className='text-start align-self-center'>
                        <h6 >{"Email"}</h6>
                      </Col>
                      <Col md="8" className='text-start align-self-center'>
                        <p className="text-muted mb-0">{request.email}</p>
                      </Col>
                    </Row>
                    <Row className='text-start align-self-center'>
                      <Col md="4" className='text-start align-self-center'>
                        <h6 >{"Phone"}</h6>
                      </Col>
                      <Col md="8" className='text-start align-self-center'>
                        <p className="text-muted mb-0">{request.phone}</p>
                      </Col>
                    </Row>
                    <Row className='text-start align-self-center'>
                      <Col md="4" className='text-start align-self-center'>
                        <h6 >{"Ville"}</h6>
                      </Col>
                      <Col md="8" className='text-start align-self-center'>
                        <p className="text-muted mb-0">{request.city}</p>
                      </Col>
                    </Row>
                    <Row className='text-start align-self-center'>
                      <Col md="4" className='text-start align-self-center'>
                        <h6 >{"Age"}</h6>
                      </Col>
                      <Col md="8" className='text-start align-self-center'>
                        <p className="text-muted mb-3">{request.age + " ans"}</p>
                      </Col>
                    </Row>
                    <hr></hr>
                    <h4 className="text-center mb-3">{request.car_make + " " + request.car_model}</h4>
                    <hr></hr>
                    <Row className='text-start align-self-center'>
                      <Col md="6" className='text-start align-self-center'>
                        <h6 className="mb-4 mt-3">
                          Duration  :{" "}
                          <b>{days + " days"} </b>
                        </h6>
                      </Col>
                      <Col md="6" className='text-start align-self-center'>
                        <h5 className="mb-4 mt-3">
                          Total  :{" "}
                          <b>{request.total} MAD</b>
                        </h5>
                      </Col>
                    </Row>


                    

                    <Row className="mb-2">
                      <Col md="6">

                        <div >
                          <p className="font-size-14">
                            <i
                              className=
                              " fa fa-caret-right font-size-16 align-middle text-primary me-2"
                            />
                            {"Pickup date     :   "}

                          </p>
                        </div>

                      </Col>
                      <Col md="6">
                        <div >
                          <p className="text-muted">

                            {request.pickup_date}
                          </p>
                        </div>

                      </Col>
                    </Row>
                    <Row className="mb-0">
                      <Col md="6">

                        <div >
                          <p className="font-size-14">
                            <i
                              className=
                              " fa fa-caret-right font-size-16 align-middle text-primary me-2"
                            />
                            {"Return date     :   "}

                          </p>
                        </div>

                      </Col>
                      <Col md="6">
                        <div >
                          <p className="text-muted">

                            {request.return_date}
                          </p>
                        </div>

                      </Col>
                    </Row>


                    <Alert color="info" role="alert">

<p className="text-muted mb-2 font-size-11 ">
  <i className="bx bx-info-circle font-size-16 align-middle text-primary me-2" />
  {"The client with a blue badge is a verified user with all informations like identity and drivers license are verified by Medios."}
</p>
<p className="text-muted mb-2 font-size-11">
  <i className="bx bx-info-circle font-size-16 align-middle text-primary me-2" />
  {"Please check  booking details carefully, before accepting or denying, in order to prevent any future problems with the client at the pickup or the return."}
</p>
<p className="text-muted font-size-11">
  <i className="bx bx-info-circle font-size-16 align-middle text-primary me-2" />
  {"Note that the number of denied online reservations may affect the overall rating of your agency."}
</p>
</Alert>

                    <Row >
                      <Col className="text-start mt-4">
                        <Button color="danger" >
                          Deny
                        </Button>
                      </Col>
                      <Col className="text-end mt-4">
                        <Button type="submit" color="success">
                          Accept reservation
                        </Button>

                      </Col>

                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </ModalBody>
        </Modal>

      </React.Fragment>
    )
  }
}

CardContact.propTypes = {
  request: PropTypes.object,

}

export default CardContact

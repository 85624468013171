import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';
import "./../../Rental/print.css"

import { withTranslation } from "react-i18next"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"

import maintanence from "../../../assets/images/coming-soon.svg"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
/* import {
    getContract,
    addNewContract,
    updateContract,
    deleteContract
} from "store/actions" */


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";

class Contracts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_id: "",
      loading: false,
      con_modal: false,
      submitLoading: false,
      isShow: false,
      changed: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      selectedGroup: null,
      reservation: [],
      agency: [],
      contracts: [],
      contract: [],
      client_infos: [],
      date_birth: new Date(),
      ContractColumns: [

        {
          dataField: "contract_name",
          text: this.props.t("name"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.contract_name}
            </Link>
          ),
        },
        {
          dataField: "daily_rate",
          isDummyField: true,
          text: this.props.t("Daily Rate"),
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <i className="far fa-clock me-1"
              />{" "}
              {row.daily_rate} dh
            </>
          ),
        },

        {
          dataField: "monthly_rate",
          isDummyField: true,
          text: this.props.t("Monthly Rate"),
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <i className="far fa-calendar-alt me-1"
              />{" "}
              {row.monthly_rate} dh
            </>
          ),
        },

        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, contract) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleContractClick(contract)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: contract })} />
                </Link>
              </div>
            </>
          ),
        },

      ],
      selectedFiles: []
    }
    this.toggleCon = this.toggleCon.bind(this)
    this.handleContractClick = this.handleContractClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidContractSubmit = this.handleValidContractSubmit.bind(this)
    this.handleContractClicks = this.handleContractClicks.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)
  }



  /**
   * Formats the size
   */



  componentDidMount() {
    const { reservation, agency, contracts, onGetContract } = this.state
    const pathParts = window.location.pathname.split('/');
    const url = window.location.href.toString()
    const r_id = url.substring(url.lastIndexOf("/") + 1)
    if (pathParts.length > 2 && pathParts[2]) {
      this.setState({ con_modal: true });
    } else {
      this.setState({ con_modal: false });
    }
    if (contracts && !contracts.length) {
      //onGetContract()
      this.getContract()
    }
    this.setState({ contracts })
    if (reservation && !reservation.length) {
      this.getReservationData(r_id)
    }
    this.setState({ reservation })
    if (agency && !agency.length) {
      this.getAgency()
    }
    this.setState({ agency })
  }
  toggleCon() {
    this.setState(prevState => ({
      con_modal: !prevState.con_modal,
    }))
  }

  getAgency = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userUsersCollectionRef = usersCollection.doc(userId)


            this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
              let kra = snapshot.data()
              this.setState({
                loading: false,
                agency: kra,
              })
            });



            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });
  }
  getReservationData = async (id) => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;

            const rDoc = await usersCollection.doc(userId).collection("reservations").doc(id).get();
            if (rDoc.exists) {
              let kra = null, cl = null, cli = null
              kra = rDoc.data()

              this.setState({
                loading: false,
                reservation: kra,
              })
              const cDoc = await usersCollection.doc(userId).collection("indies").doc(rDoc.data().client_id).get();
              if (cDoc.exists) {
                cli = cDoc.data()

                this.setState({
                  loading: false,
                  client_infos: cli,
                })
              }
              const vDoc = await usersCollection.doc(userId).collection("vehicles").doc(rDoc.data().v_id).get();
              if (vDoc.exists) {
                this.setState({
                  loading: false,
                  car_make: vDoc.data().make,
                  car_model: vDoc.data().model,
                })
              }
            }
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });


  }
  getContract = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeContracts) {
      this.unsubscribeContracts();
    }

    this.unsubscribeContracts = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userContractsCollectionRef = usersCollection.doc(userId).collection("contracts");
            this.setState({
              user_id: userId,
              account_user: userDoc.data()
            })
            this.unsubscribeContracts = userContractsCollectionRef.onSnapshot(snapshot => {
              let t = [];
              snapshot.docs.forEach(doc => {
                t.push(doc.data());
              });

              this.setState({
                loading: false,
                contracts: t
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              contracts: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          contracts: []
        });
      }
    });

  }
  // eslint-disable-next-line no-unused-vars
  /* componentDidUpdate(prevProps, prevState, snapshot) {
      const { contracts } = this.state


  } */

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  handleContractClicks = () => {
    this.setState({ contract: [], isEdit: false, isShow: false })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { contracts } = this.props
    this.setState({
      contracts: contracts.filter(contract =>
        Object.keys(contract).some(
          key =>
            typeof contract[key] === "string" &&
            contract[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }


  /* Insert,Update Delete data */

  handleDeleteContract = (contract) => {


    const { onDeleteContract } = this.state
    /* if (contract['code'] === '') {
        //
    } else { */
    //onDeleteContract(contract)
    this.deleteContract(contract)
    this.toggleDelete()
    //}

  }

  handleViewClick = arg => {
    const contract = arg

    this.setState({
      contract: {
        id: contract.id,
        code: contract.code,
        contract_name: contract.contract_name,
        daily_rate: contract.daily_rate,
        monthly_rate: contract.monthly_rate,

      },

      isShow: true,
      isEdit: false
    })

    //this.toggleIsShow()
    this.toggle()

    //this.toggleViewModal()
  }


  handleContractClick = arg => {
    const contract = arg

    //this.setState({selectedGroup:staff.designation})
    this.setState({
      contract: {
        id: contract.id,
        code: contract.code,
        contract_name: contract.contract_name,
        daily_rate: contract.daily_rate,
        monthly_rate: contract.monthly_rate,
      },
      isEdit: true,
      isShow: false,
    })

    this.toggle()
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidContractSubmit = (e, values) => {
    const { isEdit, contracts, selectedContract } = this.state
    const cds = []
    this.state.contracts.map(spl =>
      cds.push(spl.code)
    )
    const cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1
    /*  let des= this.state.selectedGroup.value
     if(this.state.changed){
       des= this.state.selectedGroup.value
       this.setState({changed:false})
     }else{
       des=this.state.selectedGroup
     }
      */

    /* this.setState({
      gender:this.state.staffs.gender
    }) */
    var na = 0, naA = 0
    const foundna = contracts.filter(element => element.contract_name === values.contract_name && this.state.contract.id !== element.id);
    na = foundna.length


    const foundnaA = contracts.filter(element => element.contract_name === values.contract_name);
    naA = foundnaA.length
    if (isEdit) {
      //des=this.state.selectedGroup.defaultInputValue

      const updateContract = {
        code: cd,
        id: this.state.contract.id,
        contract_name: values.contract_name.trim(),
        daily_rate: parseInt(values.daily_rate),
        monthly_rate: parseInt(values.monthly_rate),
      }

      // update Order
      ///onUpdateContract(updateContract)
      if (this.state.contract.contract_name == updateContract.contract_name
        && this.state.contract.daily_rate == updateContract.daily_rate
        && this.state.contract.monthly_rate == updateContract.monthly_rate) {
        this.setState({ submitLoading: false })
        this.toggle()
      } else {
        this.updateContract(updateContract, na)
      }

    } else {

      const newContract = {
        code: cd,
        contract_name: values['contract_name'],
        daily_rate: parseInt(values['daily_rate']),
        monthly_rate: parseInt(values['monthly_rate']),
      }
      ////console.log(des)
      // save new Order
      //onAddNewContract(newContract)
      this.setState({ contract: newContract })
      this.addNewContract(newContract, naA)
    }
    this.setState({ selectedContract: null })
    //this.toggle()
  }

  deleteContract = (contract) => {
    ////console.log(supplier)
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("contracts")
    const timeline = {
      statusTitle: "Contract deleted",
      iconClass: "bx bx-money h2 text-danger",
      description: "Contract information has been deleted for ",
      data: contract.contract_name,
      time: new Date().getTime(),

    }
    collection.doc(contract.id).delete().then(() => {

      //console.log("Element  deleted!");
      toast.success(this.props.t("Contract infos Deleted "))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Contract has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"))

    });

  }

  updateContract = (contract, na) => {

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("contracts")
    const timeline = {
      statusTitle: "Contract updated",
      iconClass: "bx bx-money h2 text-warning",
      description: "Contract information has been updated for ",
      data: contract.contract_name,
      time: new Date().getTime(),

    }

    if (na == 0) {


      collection.doc(contract.id).update({
        code: contract.code,
        contract_name: contract.contract_name,
        daily_rate: contract.daily_rate,
        monthly_rate: contract.monthly_rate,

      }).then(() => {
        //console.log("Document Updated!");
        toast.success(this.props.t("Contract updated "))
        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()
      }).catch((error) => {
        console.error("Error updating document: ", error);
        toast.error(this.props.t("Error updating document"))
        this.setState({ submitLoading: false })
      });
    } else {

      if (na != 0) {
        console.error("Contract already exist ");
        toast.error(this.props.t("Contract already exist"))
        this.setState({ submitLoading: false })
      }
    }
  }

  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }
  addNewContract = (contract, na) => {

    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("contracts")

    this.setState({ submitLoading: true })
    const timeline = {
      statusTitle: "New Contract created",
      iconClass: "bx bx-money h2 text-success",
      description: "Contract information has been created ",
      data: contract.contract_name,
      time: new Date().getTime(),

    }
    if (na == 0) {

      var newDoc = collection.doc();
      newDoc.set(
        {
          id: newDoc.id,
          code: contract.code,
          contract_name: contract.contract_name,
          daily_rate: contract.daily_rate,
          monthly_rate: contract.monthly_rate,

        }).then(() => {
          //console.log("trf  written!");
          toast.success(this.props.t("Contract added "))
          this.addNewTimeline(timeline)
          this.setState({ submitLoading: false })
          this.toggle()
        })
        .catch((error) => {
          console.error("Error Adding contract: ", error);
          toast.error(this.props.t("Error Adding Contract"))
          this.setState({ submitLoading: false })
        });

    } else {

      if (na != 0) {
        console.error("Contract already exist ");
        toast.error(this.props.t("Contract already exist"))
        this.setState({ submitLoading: false })
      }
    }
  }

  printContract = () => {
    window.print()
  }

  render() {

    const emptyDataMessage = () => {

      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-1 mb-2">{this.props.t("No Data")}</h4>
                {/* <p className="text-muted">
                                    {this.props.t("Start adding your Contracts by clicking")}<h5 className="text-primary"><Link onClick={this.handleContractClicks} >{this.props.t("New Contract")}</Link></h5>
                                </p> */}


              </div>
            }

          </Col>
        </Row>
      </Container>


        ;
    }


    const { contracts, agency, reservation, car_make, car_model, loading, client_infos } = this.state
    const subtotal = parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers)
    const tva = (parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers)) * 0.2
    const total = subtotal + tva
    console.log(reservation)
    const data = contracts;
    let dataxcl = []
    data.map(function (item) {
      delete item.contract;
      dataxcl.push({
        contract_name: item.contract_name,
        daily_rate: item.daily_rate,
        monthly_rate: item.monthly_rate,
      })
    });

    const { selectedGroup } = this.state
    const { SearchBar } = Search
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc } = this.state

    //pagination customization


    const defaultSorted = [{
      dataField: 'daily_rate',
      order: 'asc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };
    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: 'Contracts', exportType: exportFromJSON.types.xls })
    }
    function exportPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Contracts Infos";
      const headers = [["Contract Name", "Daily Rate", "Monthly Rate"]];

      const dt = data.map(elt => [elt.contract_name, elt.daily_rate, elt.monthly_rate]);

      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      doc.save("Contracts.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Contracts Infos";
      const headers = [["Contract Name", "Daily Rate", "Monthly Rate"]];

      const dt = data.map(elt => [elt.contract_name, elt.daily_rate, elt.monthly_rate]);

      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }

    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Contracts | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Modal isOpen={this.state.con_modal} size="lg" scrollable={true}>
              <ModalHeader toggle={this.toggleCon}>
              </ModalHeader>
              <ModalBody >

                <Row className="text-center"  >
                  {loading ? <Col xs="12">
                    <div className="text-center my-3">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin me-2" />
                        {this.props.t("Loading . . .")}
                      </Link>
                    </div>
                  </Col> :
                    <Col lg="12" id="down">

                      <Card >

                        <CardBody>
                          <div className="print-container">
                            <div className="print-header">
                              <header>
                                <Row className="justify-items-center">
                                  {/* <Col className="col-sm-7 text-center text-sm-start mb-2  mb-sm-0"> <img id="logo" src={logo} width="90" height="30" title="Medios" alt="Medios"></img> </Col> */}
                                  <Col className=" text-center ">
                                    <h4 className="mb-0 ">{this.props.t("Rental agreement")}</h4>
                                    <p className="mb-0 ">{this.props.t("Contract Number")} : <span className="fw-bold">{reservation.code}</span></p>
                                  </Col>
                                </Row>

                              </header>
                            </div>

                            <div className="print-content">
                              <Container fluid>
                                <h5 className="text-start fw-bold mt-0 mb-2">{this.props.t("Vehicle & Rental Details")} : </h5>
                                <hr className="my-1" />
                                <Row className="text-center">
                                  <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase ">{this.props.t("Make & Model")}:</span><br />
                                    <span className="fw-700 text-6">{car_make + " " + car_model}</span></Col>

                                  {/* <Col className="col-sm-3 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Particular")}:</span><br />
                                  <span className="fw-700 text-6">{this.props.t("Car on Self Drive")}</span> </Col> */}
                                  <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Registration Number")}:</span><br />
                                    <span className="fw-700 text-6">{reservation.plate_number}</span> </Col>
                                  <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Car Category")}:</span><br />
                                    <span className="fw-700 text-6">{reservation.vehicle_type}</span></Col>
                                </Row>
                                <hr className="my-0" />
                                <Row >
                                  <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Rental Start Date")}:</span><br />
                                    <span className="fw-700 text-6">{moment(reservation.pickup_date).format('DD-MM-YYYY')}</span> </Col>
                                  <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Rental End Date")}:</span><br />
                                    <span className="fw-700 text-6">{moment(reservation.return_date).format('DD-MM-YYYY')}</span> </Col>
                                  <Col className="col-sm-4 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Total Day")}<small>(s)</small>:</span><br />
                                    <span className="fw-700 text-6">{reservation.days}</span> </Col>

                                </Row>
                                <hr className="my-0" />
                                <Row >
                                  <Col className="col-sm-3 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Out km")}<small>(s)</small>:</span><br />
                                    <span className="fw-700 text-6">{reservation.mileage_pickup || "----"}</span> </Col>
                                  <Col className="col-sm-3 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("In km")}<small>(s)</small>:</span><br />
                                    <span className="fw-700 text-6">{reservation.mileage_return || "----"}</span> </Col>
                                  <Col className="col-sm-3 mb-2s mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Total km")}<small>(s)</small>:</span><br />
                                    <span className="fw-700 text-6">{(reservation.mileage_return !== null && reservation.mileage_pickup !== null && reservation.mileage_return - reservation.mileage_pickup > 0) ? reservation.mileage_return - reservation.mileage_pickup || "----" : "----"}</span> </Col>
                                  <Col className="col-sm-3 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Security Deposit")}:</span><br />
                                    <span className="fw-700 text-6">{reservation.caution + " dh" || " ----"}</span> </Col>
                                </Row>
                                <hr className="my-1" />
                                <h5 className="text-start fw-bold mt-2 mb-2">{this.props.t("Client Information")} :</h5>

                                <div >
                                  <table className="table rounded-table table-sm">
                                    <tbody>
                                      <tr>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("Name")} :</span><br />
                                          {client_infos.first_name + " " + client_infos.last_name}
                                        </td>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("Date of Birth")}:</span><br />
                                          {client_infos.date_birth}
                                        </td>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("CIN/Passport Number")}:</span><br />
                                          {client_infos.id_number}
                                        </td>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("Valid Till")}:</span><br />
                                          {client_infos.id_expiration_date}
                                        </td>


                                      </tr>
                                      <tr>
                                        <td className="col-4 text-start">
                                          <span className="fw-bold">{this.props.t("Driving License Number")}:</span><br />
                                          {client_infos.dl_number}
                                        </td>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("Phone")}:</span><br />
                                          {client_infos.mobile}
                                        </td>

                                        <td className="col-5 text-start" colSpan="2">
                                          <span className="fw-bold">{this.props.t("Address")}:</span><br />
                                          {client_infos.address + ", " + client_infos.city + ", " + client_infos.country}
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                                <h5 className="text-start fw-bold  mb-2">{this.props.t("Second Driver")} :</h5>

                                <div >
                                  <table className="table rounded-table table-sm">
                                    <tbody>
                                      <tr>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("Name")} :</span><br />
                                          <br />
                                        </td>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("Date of Birth")}:</span><br />

                                        </td>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("CIN/Passport Number")}:</span><br />

                                        </td>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("Valid Till")}:</span><br />

                                        </td>


                                      </tr>
                                      <tr>
                                        <td className="col-4 text-start">
                                          <span className="fw-bold">{this.props.t("Driving License Number")}:</span><br />
                                          <br />
                                        </td>
                                        <td className="col-3 text-start">
                                          <span className="fw-bold">{this.props.t("Phone")}:</span><br />

                                        </td>

                                        <td className="col-5 text-start" colSpan="2">
                                          <span className="fw-bold">{this.props.t("Address")}:</span><br />

                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                                <h5 className="text-start fw-bold mt-2 ">{this.props.t("Charges & Fees")} : </h5>
                                <div className="">
                                  <table className="table table-border  table-sm">
                                    <thead className="card-header">
                                      <tr>
                                        <td className=" text-start fw-bold">{this.props.t("Description")}</td>
                                        <td className=" text-end fw-bold">{this.props.t("Unit Price (MAD)")}</td>
                                        <td className=" text-end fw-bold">{this.props.t("Unit")}</td>
                                        <td className=" text-end fw-bold">{this.props.t("Quantity")}</td>
                                        <td className=" text-end fw-bold">{this.props.t("Total (MAD)")}</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className=" text-start">{this.props.t("Net de location")} </td>
                                        <td className=" text-end">{reservation.amount / reservation.days}</td>
                                        <td className=" text-end">{this.props.t("Days")}</td>
                                        <td className=" text-end">{reservation.days}</td>
                                        <td className=" text-end">{reservation.amount}</td>
                                      </tr>

                                      <tr>
                                        <td className=" text-start">{this.props.t("Delivery fee")}<br />
                                          {/* <span className="text-1">SUMMERFUN - 10% One Time Discount</span>*/}</td>
                                        <td className="text-end">{reservation.frais_livraison}</td>
                                        <td className="text-end">{this.props.t("-")}</td>
                                        <td className="text-end">{1}</td>
                                        <td className="text-end">{reservation.frais_livraison}</td>
                                      </tr>
                                      <tr>
                                        <td className=" text-start">{this.props.t("Return fee")}<br />
                                          {/* <span className="text-1">SUMMERFUN - 10% One Time Discount</span>*/}</td>
                                        <td className="text-end">{reservation.frais_reprise}</td>
                                        <td className="text-end">{this.props.t("-")}</td>
                                        <td className="text-end">{1}</td>
                                        <td className="text-end">{reservation.frais_reprise}</td>
                                      </tr>
                                      <tr>
                                        <td className=" text-start">{this.props.t("Divers")}</td>
                                        <td className="text-end">{reservation.frais_divers}</td>
                                        <td className="text-end">{this.props.t("-")}</td>
                                        <td className="text-end">{1}</td>
                                        <td className="text-end">{reservation.frais_divers}</td>
                                      </tr>
                                    </tbody>
                                    <tfoot className="card-footer">
                                      <tr>
                                        <td colSpan="4" className="text-end border-bottom-0"><strong>{this.props.t("Sub Total")}:</strong></td>
                                        <td className="text-end border-bottom-0">{subtotal}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan="4" className="text-end border-bottom-0"><strong>{this.props.t("TVA 20%")}:</strong></td>
                                        <td className="text-end border-bottom-0">{tva}</td>
                                      </tr>

                                      <tr>
                                        <td colSpan="4" className="text-end border-bottom-0 "><strong>{this.props.t("Total")}:</strong></td>
                                        <td className="text-end border-bottom-0"><strong>{total}</strong></td>
                                      </tr>
                                    </tfoot>

                                  </table>

                                  <hr className="mb-1"></hr>

                                  <p className="text-sm mt-1 mb-1">
                                    <small>
                                      <strong>
                                        {this.props.t("The tenant acknowledges their responsibility for any damages incurred to the car unless the existence of another party responsible for the damages is established.")}
                                      </strong >
                                    </small>
                                  </p>

                                  <hr className="mt-1"></hr>
                                </div>


                                <h5 className="text-start fw-bold mt-0 mb-2">{this.props.t("Signatures")} : </h5>
                                <table className="table border  rounded mb-0">
                                  <tbody className="card-header">
                                    <tr>
                                      <td className="col-4 text-start fw-bold">{agency.company_name}</td>
                                      <td className="col-4 text-center fw-bold">{this.props.t("The tenant")}</td>
                                      <td className="col-4 text-end fw-bold">{this.props.t("Return")}</td>
                                    </tr>
                                    <tr>
                                      <td className="col-4 text-start" ><br /> <br /><br /></td>
                                      <td className="col-4 text-center" ><br /><br /><br /></td>
                                      <td className="col-4 text-end" ><br /><br /><br /></td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <Col className="d-flex align-items-center mb-2">
                                <div
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    border: '2px solid #000', // Border only
                                    marginRight: '10px',
                                  }}
                                ></div>
                                <span>kra kolo</span>
                              </Col> */}
                              </Container>

                              {/* <div className="page-break"></div> */}
                              <Row >
                                <Col lg="12">
                                  <Card>
                                    <CardBody>
                                      <h5 className="mt-4">{this.props.t("Les termes et les conditions")}{"   -   "}{this.props.t("الأحكام و الشروط")}</h5>

                                      {/* Terms and Conditions content */}
                                      <div className="terms-container ">
                                        <Row>
                                          {/* French Terms (Left Side) */}
                                          <Col className="french-terms">

                                            <table className="terms-table mt-2">

                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <strong>1. Âge et assurance</strong>
                                                    <ul>
                                                      <li>Âge minimum de location : 20 ans</li>
                                                      <li>Âge maximum de location : 68 ans</li>
                                                      <li>Assurance complète selon les conditions de la société</li>
                                                      <li>L&apos;assurance couvre les accidents de la route sans limitation</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>1. السن والتأمين</strong>
                                                    <ul>
                                                      <li>الحد الأدنى للتأجير: 20 سنة</li>
                                                      <li>الحد الأقصى للتأجير: 68 سنة</li>
                                                      <li>تأمين شامل وفقًا لشروط الشركة</li>
                                                      <li>التأمين يغطي حوادث السير دون تحديد</li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <strong>2. Conducteur supplémentaire</strong>
                                                    <ul>
                                                      <li>Un conducteur supplémentaire gratuit autorisé</li>
                                                      <li>Le conducteur supplémentaire doit être présent lors de la signature du contrat</li>
                                                      <li>Permis de conduire valide depuis au moins un an requis</li>
                                                      <li>Le conducteur supplémentaire assume les mêmes responsabilités que le conducteur principal</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>2. السائق الإضافي</strong>
                                                    <ul>
                                                      <li>يُسمح بسائق إضافي واحد مجانًا</li>
                                                      <li>يجب حضور السائق الإضافي عند توقيع العقد</li>
                                                      <li>يجب تقديم رخصة قيادة سارية لمدة لا تقل عن سنة</li>
                                                      <li>يتحمل السائق الإضافي نفس مسؤوليات السائق الأساسي</li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <strong>3. Dommages et responsabilités</strong>
                                                    <ul>
                                                      <li>Le locataire est responsable de tous les dommages, vols ou incendies</li>
                                                      <li>Le locataire supporte les pertes journalières de la société dues à l&apos;immobilisation du véhicule</li>
                                                      <li>Obligation d&apos;informer immédiatement la société de tout accident, vol ou incendie avec rapport de police</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>3. الأضرار والمسؤوليات</strong>
                                                    <ul>
                                                      <li>المستأجر مسؤول عن جميع الأضرار، السرقة، أو الحريق</li>
                                                      <li>يتحمل المستأجر الخسائر اليومية للشركة نتيجة توقف السيارة</li>
                                                      <li>يجب إبلاغ الشركة فورًا بأي حادث، سرقة، أو حريق مع تقديم تقرير الشرطة</li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <strong>4. Durée de location</strong>
                                                    <ul>
                                                      <li>Durée minimale de location : 48 heures</li>
                                                      <li>Tout dépassement d&apos;une heure entraîne la facturation d&apos;une journée supplémentaire</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>4. مدة الإيجار</strong>
                                                    <ul>
                                                      <li>الحد الأدنى لفترة الإيجار: 48 ساعة</li>
                                                      <li>تجاوز الحد الأدنى بساعة واحدة يؤدي إلى فوترة يوم إضافي</li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <strong>5. Paiement</strong>
                                                    <ul>
                                                      <li>Le paiement de la location doit être effectué à l&apos;avance</li>
                                                      <li>La société accepte les paiements par chèque</li>
                                                      <li>Le dépôt de garantie n&apos;est remboursable en aucun cas</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>5. الدفع</strong>
                                                    <ul>
                                                      <li>يتم دفع مستحقات الإيجار مسبقًا</li>
                                                      <li>تقبل الشركة الدفع بالشيكات</li>
                                                      <li>مبلغ التأمين غير قابل للاسترداد تحت أي ظرف</li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <strong>6. Entretien et réparation</strong>
                                                    <ul>
                                                      <li>Le véhicule est livré en bon état et doit être rendu dans le même état</li>
                                                      <li>Le locataire paie les frais de réparation en cas de dommage ou de panne</li>
                                                      <li>Les pannes mécaniques naturelles sont à la charge de la société propriétaire</li>
                                                      <li>Tous les cinq pneus doivent être en bon état lors de la restitution du véhicule</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>6. الصيانة والإصلاح</strong>
                                                    <ul>
                                                      <li>تُسلم السيارة للمستأجر في حالة جيدة ويجب إعادتها بنفس الحالة</li>
                                                      <li>المستأجر يدفع مصاريف الإصلاح في حالة أي عطب أو تلف</li>
                                                      <li>الأعطاب الميكانيكية الطبيعية تتحملها الشركة المالكة</li>
                                                      <li> يجب أن تكون جميع العجلات الخمس في حالة جيدة عند إعادة السيارة</li>
                                                    </ul>
                                                  </td>
                                                </tr>




                                                <tr>
                                                  <td>
                                                    <strong>7. Utilisation du véhicule</strong>
                                                    <ul>
                                                      <li>La location est personnelle et ne peut être transférée à une autre personne</li>
                                                      <li>Le locataire n&apos;a pas le droit de faire conduire une personne non mentionnée dans le contrat</li>
                                                      <li>Distance limitée à 200 km par 24 heures, avec un supplément de 0,85 DH par km supplémentaire</li>
                                                      <li>Un accord préalable est nécessaire pour les longs trajets</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>7. استعمال السيارة</strong>
                                                    <ul>
                                                      <li>الإيجار للمستأجر شخصيًا وليس له الحق في نقله لشخص آخر</li>
                                                      <li> لا يحق للمستأجر اتخاذ سائق آخر غير المحدد في العقد</li>
                                                      <li>المسافة محددة بـ 200 كم في 24 ساعة، مع دفع 0.85 درهم لكل كم إضافي</li>
                                                      <li> يجب الاتفاق مسبقًا في حالة السفر لمسافات طويلة</li>
                                                    </ul>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>
                                                    <strong>8. Documents du véhicule</strong>
                                                    <ul>
                                                      <li>Tous les documents relatifs au véhicule doivent être restitués lors du retour</li>
                                                      <li>La location est considérée comme continue jusqu&apos;à la restitution des documents du véhicule à la société</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>8. وثائق السيارة</strong>
                                                    <ul>
                                                      <li>يجب إعادة كل الوثائق المتعلقة بالسيارة عند إرجاعها</li>
                                                      <li>يعتبر الإيجار مستمرًا حتى إعادة وثائق السيارة للشركة</li>
                                                    </ul>
                                                  </td>
                                                </tr>


                                                <tr>
                                                  <td>
                                                    <strong>9. Assurance</strong>
                                                    <ul>
                                                      <li>L&apos;assurance couvre des dommages limités conformément à la réglementation en vigueur au Maroc</li>
                                                      <li>En cas d&apos;accident ou de dommage, un rapport de police doit être fourni</li>
                                                      <li>En cas de vol du véhicule, la police et la société de location doivent être informées immédiatement</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>9. التأمين</strong>
                                                    <ul>
                                                      <li>يغطي التأمين أضرارًا محدودة وفقًا للوائح المعمول بها في المغرب</li>
                                                      <li>في حالة وقوع حادث أو ضرر، يجب إحضار تقرير الشرطة</li>
                                                      <li>في حالة سرقة السيارة، يجب إبلاغ الشرطة والشركة المؤجرة فورًا</li>
                                                    </ul>
                                                  </td>
                                                </tr>


                                                <tr>
                                                  <td>
                                                    <strong>10. Remarques importantes</strong>
                                                    <ul>
                                                      <li>Le contrat de location est immédiatement annulé si le véhicule est conduit par une personne non mentionnée dans le contrat</li>
                                                      <li>Le contrat est annulé en cas de conduite imprudente ou d&apos;utilisation acrobatique du véhicule</li>
                                                      <li>Le locataire est responsable de toutes les infractions au code de la route et des amendes qui en résultent</li>
                                                    </ul>
                                                  </td>
                                                  <td className="arabic-terms">
                                                    <strong>10. ملاحظات هامة</strong>
                                                    <ul>
                                                      <li>يتم إلغاء عقد الإيجار فورًا إذا ثبت قيادة السيارة من شخص غير الموقع على العقد</li>
                                                      <li>يتم إلغاء العقد في حالة القيادة المتهورة أو القيام بألعاب بهلوانية بالسيارة</li>
                                                      <li>المستأجر مسؤول عن جميع المخالفات المرورية والغرامات المالية الناتجة عنها</li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </Col>

                                          {/* Arabic Terms (Right Side) */}

                                        </Row>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                            <div className="print-footer">

                              <p><strong>{agency.company_name}</strong><br />
                                {agency.address} - {this.props.t("Email")} : {agency.email} <br />
                                {this.props.t("Tél")}: {agency.phone} - {this.props.t("RC")} : {agency.rc} - {this.props.t("T.P")}: {agency.tp} - {this.props.t("ICE")}: {agency.ice} - {this.props.t("IF")}: {agency.id_fiscal}.
                              </p>
                            </div>
                          </div>
                        </CardBody>


                      </Card>
                      <div className="d-print-none mb-4">
                        <div className="float-center">
                          <Link
                            to="#"
                            onClick={this.printContract}
                            className="btn btn-success me-1"
                          >
                            {this.props.t("Print")}{" "}<i className="fa fa-print" />
                          </Link>{" "}
                          {/* <Link
                          to="#"
                          onClick={generatePDF}
                          className="btn btn-primary me-1"
                        >
                          {this.props.t("Save as PDF")}{" "}<i className="fa fa-file-pdf" />
                        </Link>{" "} */}
                        </div>
                      </div>
                    </Col>

                  }


                </Row>

              </ModalBody>
            </Modal>
            <Breadcrumbs title={this.props.t("Rental")} breadcrumbItem={this.props.t("Contracts")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.ContractColumns || [])}
                      data={(contracts || [])}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={contracts}
                          columns={(this.state.ContractColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" class="noPrint">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleContractClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      {this.props.t("New Contract")}
                                    </Button>

                                    <Link to="#"

                                    >

                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-18" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => this.state.contracts.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.contracts.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.contracts.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                                <Modal
                                  isOpen={this.state.modal}
                                  className={this.props.className}
                                  size='md'
                                >
                                  <ModalHeader toggle={this.toggle} tag="h4">
                                    {!!isEdit ? this.props.t("Edit Contracts") : null}
                                    {!!this.state.isShow ? this.props.t("Contracts Infos") : null}
                                    {!isEdit && !this.state.isShow ? this.props.t("Add Contract") : null}
                                  </ModalHeader>
                                  <ModalBody>
                                    {this.state.submitLoading ?

                                      <Col>
                                        <div>
                                          <div >
                                            <div className="spinner-chase">
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                      </Col>
                                      :
                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidContractSubmit
                                        }
                                        disabled={this.state.isShow ? true : false}
                                      >
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom01">
                                                {this.props.t("Contract name")}
                                              </Label>
                                              <AvField
                                                name="contract_name"
                                                placeholder={this.props.t("Contract name")}
                                                type="text"
                                                errorMessage={this.props.t("Enter Contract name")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="validationCustom01"
                                                value={this.state.contract.contract_name || ""}

                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>
                                        <Row>
                                          <Col md="6">

                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom01">
                                                {this.props.t("Daily Rate")}
                                              </Label>
                                              <AvField
                                                name="daily_rate"
                                                placeholder={this.props.t("Daily Rate")}
                                                type="text"
                                                errorMessage={this.props.t("Enter Daily Rate")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="validationCustom01"
                                                value={this.state.contract.daily_rate || ""}

                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom01">
                                                {this.props.t("Monthly Rate")}
                                              </Label>
                                              <AvField
                                                name="monthly_rate"
                                                placeholder={this.props.t("Monthly Rate")}
                                                type="text"
                                                errorMessage={this.props.t("Enter Monthly Rate")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="validationCustom01"
                                                value={this.state.contract.monthly_rate || ""}

                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>





                                        {this.state.isShow ? null :
                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  {this.props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        }

                                      </AvForm>
                                    }
                                  </ModalBody>
                                </Modal>


                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteContract(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}
                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}

                              </div>

                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Contracts.propTypes = {
  contracts: PropTypes.array,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  contracts: state.rental.contracts
})

const mapDispatchToProps = dispatch => ({
  onGetContract: () => dispatch(getContract())/* ,
    onAddNewContract: contract => dispatch(addNewContract(contract)),
    onUpdateContract: contract => dispatch(updateContract(contract)),
    onDeleteContract: contract => dispatch(deleteContract(contract)), */
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Contracts)))

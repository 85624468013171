import React, { Component } from "react"
import { Container, Row, Card, CardHeader, CardBody, CardTitle,Form,Col, FormGroup, LabelButton,Button, Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup, } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Components
import CardBox from "./card-box"
import img1 from "../../../assets/images/iconbox-image_01.png"
import img2 from "../../../assets/images/iconbox-image_02.png"
import img3 from "../../../assets/images/iconbox-image_03.png"

class CardsMini extends Component {
  constructor(props) {
    super(props)
    this.state = {
      coins: [
        {
          title: "Bitcoin",
          color: "warning",
          icon: "mdi mdi-bitcoin",
          value: "9134.39",
          rate: "0.0012.23 ( 0.2 % )",
          isIncrease: true,
        },
        {
          title: "Ethereum",
          color: "primary",
          icon: "mdi mdi-ethereum",
          value: "245.44",
          rate: "004.12 ( 0.1 % )",
          isIncrease: false,
        },
        {
          title: "Litecoin",
          color: "info",
          icon: "mdi mdi-litecoin",
          value: "63.61",
          rate: "0.0001.12 ( 0.1 % ) ",
          isIncrease: true,
        },
      ],
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-white p-0">
          <Container>
            <div className="currency-price">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Auto Sizing</CardTitle>

                    <Form className="row gy-2 gx-3 align-items-center">
                      <div className="col-sm-auto">
                        <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                        <Input type="text" className="form-control" id="autoSizingInput" placeholder="Jane Doe" />
                      </div>
                      <div className="col-sm-auto">
                        <Label className="visually-hidden" htmlFor="autoSizingInputGroup">Username</Label>
                        <InputGroup className="me-sm-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>@</InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" className="form-control" placeholder="Username" />
                        </InputGroup>
                      </div>
                      <div className="col-sm-auto">
                        <Label className="visually-hidden" htmlFor="autoSizingSelect">Preference</Label>
                        <Input type="select" className="form-select" id="autoSizingSelect">
                          <option defaultValue>Choose...</option>
                          <option>One</option>
                          <option>Two</option>
                          <option>Three</option>
                        </Input>
                      </div>
                      <div className="col-sm-auto">
                        <div className="form-check">
                          <Input className="form-check-input" type="checkbox" id="autoSizingCheck" />
                          <Label className="form-check-label" htmlFor="autoSizingCheck">
                            Remember me
                                                    </Label>
                        </div>
                      </div>
                      <div className="col-sm-auto">
                        <Button type="submit" color="primary" className="w-md">Submit</Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
              <br />
              <br />

              <Row className="text-center">
                <h3 >
                  Plan your trip now</h3>
                <h2>
                  Quick &amp; easy car rental
                </h2>
              </Row>
              <Row className="text-center">
                <Col md="4">
                  <img src={img1} alt="Select Car" />
                  <h3 >  Select Car </h3>
                  <p>To contribute to positive change and achieve our sustainability goals with many extraordinary </p>
                </Col>
                <Col md="4">
                  <img src={img2} alt="Contact Operator " />
                  <h3 >  Contact Operator  </h3>
                  <p>To contribute to positive change and achieve our sustainability goals with many extraordinary </p>
                </Col>
                <Col md="4">
                  <img src={img3} alt="Lets Drive" />
                  <h3 >  Let`&apos;`s Drive </h3>
                  <p>To contribute to positive change and achieve our sustainability goals with many extraordinary </p>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </React.Fragment>
    )
  }
}

export default CardsMini

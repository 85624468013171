import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import Moment from 'moment';
import * as moment from 'moment';
import { withTranslation } from "react-i18next"
import maintanence from "../../../assets/images/coming-soon.svg"
import { v4 as uuid } from "uuid"
import imageCompression from 'browser-image-compression';

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"


import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getIndie,
  addNewIndie,
  updateIndie,
  deleteIndie,
  getCompany
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";
import { indies } from "common/data";

class Indie extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account_user: [],
      clientLog: [],
      user_id: "",
      ibadl: false,
      loading: true,
      submitLoading: false,
      isShow: false,
      changed: false,
      cl_gender: "",
      viewmodal: false,
      modal: false,
      modalLog: false,
      isDelete: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      selectedGroup: "Other",
      indies: [],
      companies: [],
      indie: [],
      date_birth: new Date(),
      dl_expiration_date: new Date(),
      IndieColumns: [

        {
          dataField: "first_name",
          text: this.props.t("First name"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.first_name}
            </Link>
          ),
        },
        {
          dataField: "last_name",
          text: this.props.t("Last name"),
          sort: true,
        },
        {
          dataField: "email",
          text: this.props.t("E-mail"),
          sort: true,
          /* formatter: (cellContent, row) => (
            this.handleValidDate(row.orderdate)
          ), */
        },
        {
          dataField: "mobile",
          text: this.props.t("Phone number"),
          sort: true,
          
        },
        {
          dataField: "dl_expiration_date",
          isDummyField: true,
          text: this.props.t("Drivers license delivery"),
          sort: true,
          formatter: (cellContent, row) => (

            <>
              <Link to="#" className={Moment(new Date()).diff(Moment(row.dl_expiration_date), 'months') >= 12 ? "text-success" : (Moment(new Date()).diff(Moment(row.dl_expiration_date), 'months') > 6 ? "text-warning" : "text-danger")}>
                <i className="far fa-clock me-1" />
              </Link>{" "}

              {row.dl_expiration_date}
            </>
          ),
        },
        {
          dataField: "createdAt",
          text: this.props.t("Date created"),
          sort: true,
          formatter: (cellContent, row) => (
            <>

              {" "}
              {moment(row.createdAt).format('YYYY-MM-DDTHH:mm')}
            </>
          ),
        },
        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("History"),
          sort: true,
          formatter: (cellContent, indie) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded ms-1"
              onClick={() => this.handleLogClick(indie)}
            >
              {this.props.t("Reservations")}{" "} <i className="mdi mdi-arrow-right ms-1" />
            </Button>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Actions"),
          formatter: (cellContent, indie) => (
            <>
              <div className="d-flex gap-3">
              <a href={`https://wa.me/${indie.mobile.startsWith('+') ? indie.mobile.slice(1) : indie.mobile.startsWith('0') ? '212' + indie.mobile.slice(1) : ''}`} target="_blank" rel="noreferrer" className= "text-success" >
                <i className="mdi mdi-whatsapp mdi-18px  me-1" />
                
              </a>
                <Link to="#" className="text-primary">
                  <i className="mdi mdi-eye-outline font-size-18" id="edittooltip" onClick={() => this.handleViewClick(indie)} />
                </Link>
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleIndieClick(indie)} />
                </Link>
                
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: indie })} />
                </Link>
              </div>
            </>
          ),
        },
      ],
      selectedFiles: []
    }

    this.handleIndieClick = this.handleIndieClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleLog = this.toggleLog.bind(this)
    this.handleValidIndieSubmit = this.handleValidIndieSubmit.bind(this)
    this.handleIndieClicks = this.handleIndieClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
    this.handleGenderChange = this.handleGenderChange.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)
  }
  printIndie = () => {
    window.print()
  }
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  handleGenderChange = e => {
    const { name, value } = e.target;

    this.setState({
      cl_gender: value
    });

  };

  toLowerCase1(str) {
    return str.toLowerCase();
  }
  handleSelectGroup = selectedGroup => {

    this.setState({ selectedGroup })
    this.setState({ changed: true })
  }
  componentDidMount() {
    const { indies, onGetIndie } = this.state
    if (indies && !indies.length) {
      // onGetIndie()
      this.getIndie()
    }
    this.setState({ indies })
    const { companies, onGetCompany } = this.state
    if (companies && !companies.length) {
      this.getCompany()
    }
    this.setState({ companies })

  }

  getClientLog = async (id) => {
    this.setState({ loadingLog: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeClog) {
      this.unsubscribeClog();
    }

    this.unsubscribeClog = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userClogCollectionRef = usersCollection.doc(userId).collection("reservations").where("client_id", "==", id).orderBy("pickup_date", "desc");;

            this.unsubscribeClog = userClogCollectionRef.onSnapshot(snapshot => {
              let n = [];
              snapshot.docs.forEach(doc => {
                n.push(doc.data());
              });
              ///console.log(n)
              this.setState({
                loadingLog: false,
                clientLog: n
              });
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            // localStorage.removeItem("authUser");



          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });

  }
  getIndie = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeIndies) {
      this.unsubscribeIndies();
    }

    this.unsubscribeIndies = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userIndiesCollectionRef = usersCollection.doc(userId).collection("indies");
            this.setState({
              account_user: userDoc.data(),
              user_id: userId
            })
            this.unsubscribeIndies = userIndiesCollectionRef.onSnapshot(snapshot => {
              let n = [];
              snapshot.docs.forEach(doc => {
                n.push(doc.data());
              });

              this.setState({
                loading: false,
                indies: n
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              loading: false,
              indies: []
            });

          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          indies: []
        });
      }
    });

  }
  getCompany = async () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {

        user = firebase.auth().currentUser.uid
        ////console.log("dkhel " + user)
        const collection = firebase.firestore().collection("users")


        this.setState({ loading: true }, () => {
          collection.doc(user).collection("clients")
            .onSnapshot((doc) => {
              let kra = []
              doc.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                companies: kra,
              })
            }

            );
        });
        localStorage.setItem("authUser", JSON.stringify(user))
      } else {
        ////console.log("not user")
        localStorage.removeItem("authUser")
      }
    })

  }



  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { indies } = this.state

    if (!isEmpty(indies) && size(prevProps.indies) !== size(indies)) {
      // this.setState({ indies: {}, isEdit: false })
    }
    /* const { companies } = this.props

    if (!isEmpty(companies) && size(prevProps.companies) !== size(companies)) {
      this.setState({ companies: {}, isEdit: false })
    } */
  }
  toggleLog() {
    this.setState(prevState => ({
      modalLog: !prevState.modalLog,
    }))
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  handleIndieClicks = () => {
    this.setState({ indie: [], isEdit: false, isShow: false })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { indies } = this.props
    this.setState({
      indies: indies.filter(indie =>
        Object.keys(indie).some(
          key =>
            typeof indie[key] === "string" &&
            indie[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
      ibadl: false
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }


  /* Insert,Update Delete data */

  handleDeleteIndie = (indie) => {


    const { onDeleteIndie } = this.props
    /* if (indie['code'] === '') {
      //onDeleteIndie(order)
    } else { */
    //onDeleteIndie(indie)

    this.deleteIndie(indie)
    this.toggleDelete()
    // }

  }
  handleLogClick = arg => {

    const indie = arg

    //console.log("dkhel l log", indie.id)
    this.getClientLog(indie.id)
    this.toggleLog()
  }
  handleViewClick = arg => {
    const indie = arg

    this.setState({
      indie: {
        code: indie.code,
        first_name: indie.first_name,
        last_name: indie.last_name,
        email: indie.email,
        mobile: indie.mobile,
        address: indie.address,
        city: indie.city,
        state: indie.state,
        country: indie.country,
        cl_gender: indie.cl_gender,
        date_birth: indie.date_birth,
        notes: indie.notes,
        dl_expiration_date: indie.dl_expiration_date,
        dl_number: indie.dl_number,
        id_number: indie.id_number,
        id_expiration_date: indie.id_expiration_date
      },
      cl_gender: indie.cl_gender,
      isShow: true,
      isEdit: false
    })

    //this.toggleIsShow()
    this.toggle()
    // //console.log(this.state.isShow)
    //this.toggleViewModal()
  }


  handleIndieClick = arg => {
    const indie = arg
    this.setState({
      indie: {
        id: indie.id,
        code: indie.code,
        first_name: indie.first_name,
        last_name: indie.last_name,
        email: indie.email,
        mobile: indie.mobile,
        address: indie.address,
        city: indie.city,
        state: indie.state,
        country: indie.country,
        cl_gender: indie.cl_gender,
        date_birth: indie.date_birth,
        notes: indie.notes,
        dl_expiration_date: indie.dl_expiration_date,
        dl_number: indie.dl_number,
        id_number: indie.id_number,
        id_expiration_date: indie.id_expiration_date
      },
      isEdit: true,
      isShow: false,

      cl_gender: indie.cl_gender
    })

    this.toggle()
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidIndieSubmit = (e, values) => {
    const { onAddNewIndie, onUpdateIndie } = this.props
    const { isEdit, indies, selectedIndie } = this.state
    const cds = []
    this.state.indies.map(stf =>
      cds.push(stf.code)
    )
    this.state.companies.map(stf =>
      cds.push(stf.code)
    )
    const cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1
    /*
        let des = this.state.selectedGroup.value
        if (this.state.changed) {
          des = this.state.selectedGroup.value
          this.setState({ changed: false })
        } else {
          des = this.state.selectedGroup
        }
     */
    var em = 0, mo = 0, id = 0, dl = 0
    var emA = 0, moA = 0, idA = 0, dlA = 0

    const foundem = indies.filter(element => element.email === values.email.trim() && this.state.indie.id !== element.id);
    em = foundem.length
    const foundmo = indies.filter(element => element.mobile === values.mobile.trim() && this.state.indie.id !== element.id);
    mo = foundmo.length
    const foundid = indies.filter(element => element.id_number === values.id_number.trim() && this.state.indie.id !== element.id);
    dl = foundid.length
    const founddl = indies.filter(element => element.dl_number === values.dl_number.trim() && this.state.indie.id !== element.id);
    dl = founddl.length

    const foundemA = indies.filter(element => element.email === values.email.trim());
    emA = foundemA.length
    const foundmoA = indies.filter(element => element.mobile === values.mobile.trim());
    moA = foundmoA.length
    const foundidA = indies.filter(element => element.id_number === values.id_number.trim());
    idA = foundidA.length
    const founddlA = indies.filter(element => element.dl_number === values.dl_number.trim());
    dlA = founddlA.length

    /* this.setState({
      gender:this.state.indies.gender
    }) */

    if (isEdit) {
      //des=this.state.selectedGroup.defaultInputValue

      const updateIndie = {
        code: cd,
        id: this.state.indie.id,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        email: values.email.trim(),
        mobile: values.mobile.trim(),
        address: values.address.trim(),
        city: values.city.trim(),
        state: values.state.trim(),
        country: values.country.trim(),
        cl_gender: this.state.cl_gender,
        date_birth: values.date_birth.trim(),
        notes: values.notes.trim(),
        dl_expiration_date: values.dl_expiration_date.trim(),
        dl_number: values.dl_number.trim(),
        id_number: values.id_number.trim(),
        id_expiration_date: values.id_expiration_date.trim()
      }

      // update Order
      //onUpdateIndie(updateIndie)
      if (!this.state.ibadl && this.state.indie.first_name == updateIndie.first_name
        && this.state.indie.last_name == updateIndie.last_name
        && this.state.indie.email == updateIndie.email
        && this.state.indie.mobile == updateIndie.mobile
        && this.state.indie.designation == updateIndie.designation
        && this.state.indie.address == updateIndie.address
        && this.state.indie.city == updateIndie.city
        && this.state.indie.state == updateIndie.state
        && this.state.indie.cl_gender == updateIndie.cl_gender
        && this.state.indie.date_birth == updateIndie.date_birth
        && this.state.indie.id_number == updateIndie.id_number
        && this.state.indie.dl_number == updateIndie.dl_number
        && this.state.indie.notes == updateIndie.notes
        && this.state.indie.dl_expiration_date == updateIndie.dl_expiration_date
        && this.state.indie.id_expiration_date == updateIndie.id_expiration_date) {
        this.setState({ submitLoading: false })
        this.toggle()

      } else {
        this.updateIndie(updateIndie, /* em,  */mo, id, dl)
      }

    } else {

      const newIndie = {
        code: cd,
        first_name: values["first_name"],
        last_name: values["last_name"],
        email: values["email"],
        mobile: values["mobile"],
        address: values["address"],
        city: values['city'],
        state: values["state"],
        country: values["country"],
        cl_gender: this.state.cl_gender,
        date_birth: values["date_birth"],
        notes: values['notes'],
        dl_expiration_date: values['dl_expiration_date'],
        dl_number: values["dl_number"],
        id_number: values["id_number"],
        id_expiration_date: values["id_expiration_date"]

      }
      //  //console.log(des)
      // save new Order
      //onAddNewIndie(newIndie)
      this.setState({ indie: newIndie })
      this.addNewIndie(newIndie, /* em, */ mo, id, dl)

    }
    this.setState({ selectedIndie: null })
    //this.toggle()
  }

  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }

  deleteIndie = (indie) => {

    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("indies")
    const timeline = {
      statusTitle: "Client deleted",
      iconClass: "bx bx-user h2 text-danger",
      description: "Client information has been deleted for ",
      data: indie.first_name + " " + indie.last_name,
      time: new Date().getTime(),
    }
    collection.doc(indie.id).delete().then(() => {


      //console.log("Element  deleted!");
      toast.success(this.props.t("Client infos  deleted!"))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Client infos has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"));

    });

  }

  updateIndie = async (indie,/*  em, */ mo, id, dl) => {
    const timeline = {
      statusTitle: "Client updated",
      iconClass: "bx bx-user h2 text-warning",
      description: "Client information has been updated for ",
      data: indie.first_name + " " + indie.last_name,
      time: new Date().getTime(),

    }
    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("indies")

    if (/* em == 0 && */ mo == 0 && id == 0 && dl == 0) {

      collection.doc(indie.id).update({
        code: indie.code,
        first_name: indie.first_name,
        last_name: indie.last_name,
        email: indie.email,
        mobile: indie.mobile,
        address: indie.address,
        city: indie.city,
        state: indie.state,
        country: indie.country,
        cl_gender: indie.cl_gender,
        date_birth: indie.date_birth,
        notes: indie.notes,
        dl_expiration_date: indie.dl_expiration_date,
        dl_number: indie.dl_number,
        id_number: indie.id_number,
        id_expiration_date: indie.id_expiration_date

      }).then(() => {
        //console.log("Document Updated!");
        toast.success(this.props.t("Client infos  Updated!"))
        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()
      }).catch((error) => {
        console.error("Error updating document: ", error);
        toast.error(this.props.t("Error updating Client infos"))
        this.setState({ submitLoading: false })
      });
    } else {
      /* if (em != 0) {
        console.error("Email already exist ");
        toast.error(this.props.t("Email already exist"))
        this.setState({ submitLoading: false })
      } */
      if (mo != 0) {
        console.error("Mobile number already exist ");
        toast.error(this.props.t("Mobile number already exist"))
        this.setState({ submitLoading: false })
      }
      if (id != 0) {
        console.error("ID number already exist ");
        toast.error(this.props.t("ID number already exist"))
        this.setState({ submitLoading: false })
      }
      if (dl != 0) {
        console.error("Driver License number already exist ");
        toast.error(this.props.t("Driver License number already exist"))
        this.setState({ submitLoading: false })
      }
    }

  }
  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }
  addNewIndie = async (indie, /* em, */ mo, id, dl) => {
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("indies")
    const timeline = {
      statusTitle: "Client added",
      iconClass: "bx bx-user h2 text-success",
      description: "Client information has been added ",
      data: indie.first_name + " " + indie.last_name,
      time: new Date().getTime(),

    }
    this.setState({ submitLoading: true })

    var newDoc = collection.doc();

    if (/* em == 0 && */ mo == 0 && id == 0 && dl == 0) {

      // var newDoc = collection.doc();
      newDoc.set(
        {
          id: newDoc.id,
          code: indie.code,
          first_name: indie.first_name,
          last_name: indie.last_name,
          email: indie.email,
          mobile: indie.mobile,
          address: indie.address,
          city: indie.city,
          state: indie.state,
          country: indie.country,
          cl_gender: indie.cl_gender,
          date_birth: indie.date_birth,
          notes: indie.notes,
          dl_expiration_date: indie.dl_expiration_date,
          dl_number: indie.dl_number,
          id_number: indie.id_number,
          id_expiration_date: indie.id_expiration_date,
          createdAt: new Date().getTime(),

        }).then(() => {
          //console.log("stf  written!");
          toast.success(this.props.t('Client added '))
          this.addNewTimeline(timeline)
          this.setState({ submitLoading: false })
          this.toggle()
        })
        .catch((error) => {
          console.error("Error Adding Client: ", error);
          toast.error(this.props.t("Error Adding Client"))
          this.setState({ submitLoading: false })
        });

    } else {
      /* if (em != 0) {
        console.error("Email already exist ");
        toast.error(this.props.t("Email already exist"))
        this.setState({ submitLoading: false })
      } */
      if (mo != 0) {
        console.error("Mobile number already exist ");
        toast.error(this.props.t("Mobile number already exist"))
        this.setState({ submitLoading: false })
      }
      if (id != 0) {
        console.error("ID number already exist ");
        toast.error(this.props.t("ID number already exist"))
        this.setState({ submitLoading: false })
      }
      if (dl != 0) {
        console.error("Drivers license number already exist ");
        toast.error(this.props.t("Drivers license already exist"))
        this.setState({ submitLoading: false })
      }
    }


  }




  render() {
    const emptyDataMessage = () => {

      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your Clients informations by clicking ")}<h5 className="text-primary"><Link onClick={this.handleIndieClicks} >{this.props.t("New Client")}</Link></h5>
                </p>


              </div>
            }

          </Col>
        </Row>
      </Container>


        ;
    }
    const { indies, clientLog, loadingLog } = this.state
    //console.log(clientLog, loadingLog)
    const data = indies;
    let dataxcl = []
    let logData = []
    clientLog.map(function (item) {

      logData.push({
        code: item.code,
        plate_number: item.plate_number,
        vehicle: item.vehicle,
        pickup_date: item.pickup_date,
        pickup_location: item.pickup_location,
        return_date: item.return_date,
        return_location: item.return_location,
        days: item.days,
        amount: item.amount,
        total_amount:item.total_amount,
        notes: item.notes,
        status: item.status,
        frais_livraison: item.frais_livraison,
        frais_reprise: item.frais_reprise,
        frais_divers: item.frais_divers,
        caution: item.caution

      })
    });
    data.map(function (item) {
      delete item.indie;
      dataxcl.push({
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        mobile: item.mobile,
        address: item.address,
        city: item.city,
        state: item.state,
        country: item.country,
        cl_gender: item.cl_gender,
        date_birth: item.date_birth,
        notes: item.notes,
        dl_expiration_date: item.dl_expiration_date,
        dl_number: item.dl_number,
        id_number: item.id_number,

      })
    });

    const { cl_gender } = this.state
    const { selectedGroup } = this.state
    const { SearchBar } = Search
    const { date_birth } = this.state;
    const { dl_expiration_date } = this.state;
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc } = this.state


    function exportPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Clients Infos";
      const headers = [["Name", "Email", "Address", "Mobile", "Driver license expiration date", "notes"]];

      const dt = data.map(elt => [elt.first_name + " " + elt.last_name, elt.email, elt.address + " \n" + elt.city, elt.mobile, elt.dl_expiration_date, elt.notes]);

      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Clients.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Clients Infos";
      const headers = [["Name", "Email", "Address", "Mobile", "Driver license expiration date", "notes"]];

      const dt = data.map(elt => [elt.first_name + " " + elt.last_name, elt.email, elt.address + " \n" + elt.city, elt.mobile, elt.dl_expiration_date, elt.notes]);

      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }





    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: indies.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'createdAt',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };
    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: 'Clients', exportType: exportFromJSON.types.xls })
    }

    function ExportLogToExcel() {
      exportFromJSON({ data: logData, fileName: 'reservation history', exportType: exportFromJSON.types.xls })
    }

    /*  var myCurrentDate=new Date();
     var d=new Date(myCurrentDate);
         d.setFullYear(d.getFullYear() - 18);
     var minDate=d.getFullYear()+"-01"
         //console.log(minDate) */
    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Clients | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Client infos")} breadcrumbItem={this.props.t("Clients")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.IndieColumns || [])}
                      data={(indies || [])}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={indies}
                          columns={(this.state.IndieColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" >
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleIndieClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      {this.props.t("New Client")}
                                    </Button>

                                    <Link to="#"

                                    >

                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-18" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => this.state.indies.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.indies.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.indies.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                                <Modal
                                  isOpen={this.state.modal}
                                  className={this.props.className}
                                >
                                  <ModalHeader toggle={this.toggle} tag="h4">
                                    {!!this.state.isEdit ? this.props.t("Edit Client") : null}
                                    {!!this.state.isShow ? this.props.t("Client infos") : null}
                                    {!this.state.isEdit && !this.state.isShow ? this.props.t("Add Client") : null}
                                  </ModalHeader>
                                  <ModalBody>
                                    {this.state.submitLoading ?
                                      <Col>
                                        <div>
                                          <div >
                                            <div className="spinner-chase">
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                      </Col>
                                      :
                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidIndieSubmit
                                        }
                                        disabled={this.state.isShow ? true : false}
                                      >
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom01">
                                                {this.props.t("First name")}
                                              </Label>
                                              <AvField
                                                name="first_name"
                                                placeholder={this.props.t("First name")}
                                                type="text"
                                                errorMessage={this.props.t("Enter First Name")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="validationCustom01"
                                                value={this.state.indie.first_name || ""}

                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom02">
                                                {this.props.t("Last name")}
                                              </Label>
                                              <AvField
                                                name="last_name"
                                                placeholder={this.props.t("Last name")}
                                                type="text"
                                                errorMessage={this.props.t("Enter Last name")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="validationCustom02"
                                                value={this.state.indie.last_name || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                {this.props.t("E-mail")}
                                              </Label>
                                              <AvField
                                                name="email"
                                                placeholder={this.props.t("E-mail")}
                                                type="text"
                                                errorMessage={this.props.t("Enter a valid E-mail")}
                                                className="form-control"
                                                validate={{
                                                  /* required: { value: true }, */
                                                  email: { value: true },
                                                }}
                                                id="validationCustom03"
                                                value={this.state.indie.email || ""}
                                              />
                                            </FormGroup>
                                          </Col>


                                        </Row>
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom04">
                                                {this.props.t("Phone Number")}
                                              </Label>
                                              <AvField
                                                name="mobile"
                                                placeholder={this.props.t("Phone Number")}
                                                type="text"
                                                errorMessage={this.props.t("Enter a valid phone number")}
                                                className="form-control"

                                                validate={{
                                                  required: { value: true },
                                                  pattern: {
                                                    value: '^(0|\\+)\\d{9,14}$',
                                                    errorMessage: this.props.t("Enter a valid phone number")
                                                  },
                                                }}
                                                id="validationCustom04"
                                                value={this.state.indie.mobile || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-4">
                                              <Label> {this.props.t("Date of birth")}</Label>
                                              <AvField
                                                name="date_birth"
                                                placeholder={this.props.t("Date of birth")}
                                                type="date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={Moment(this.state.indie.date_birth).format('YYYY-MM-DD') || ""}
                                              />

                                            </FormGroup>
                                          </Col>

                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="address"
                                                label={this.props.t("Address")}
                                                type="textarea"
                                                errorMessage={this.props.t("Invalid Address")}
                                                rows="1"
                                                placeholder={this.props.t("Address")}
                                                value={this.state.indie.address || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>

                                        <Row>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="city"
                                                label={this.props.t("City")}
                                                type="text"
                                                placeholder={this.props.t("City")}
                                                value={this.state.indie.city || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="state"
                                                label={this.props.t("Region")}
                                                type="text"
                                                placeholder={this.props.t("region")}

                                                value={this.state.indie.state || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="country"
                                                label={this.props.t("Country")}
                                                type="text"
                                                placeholder={this.props.t("country")}

                                                value={this.state.indie.country || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>


                                        <Row>

                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label >
                                                {this.props.t("Drivers license Number")}
                                              </Label>
                                              <AvField
                                                name="dl_number"
                                                placeholder={this.props.t("Drivers license number")}
                                                type="text"
                                                errorMessage={this.props.t("Enter a Drivers license number")}


                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={this.state.indie.dl_number || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6 ">
                                            <FormGroup className="mb-4">
                                              <Label> {this.props.t("Drivers license delivery date")}</Label>
                                              <AvField
                                                name="dl_expiration_date"
                                                placeholder={this.props.t("Drivers license delivery date")}
                                                type="date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={Moment(this.state.indie.dl_expiration_date).format('YYYY-MM-DD') || ""}
                                              />

                                            </FormGroup>
                                          </Col>
                                        </Row>


                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label >
                                                {this.props.t("Client ID Number")}
                                              </Label>
                                              <AvField
                                                name="id_number"
                                                placeholder={this.props.t("Client ID number")}
                                                type="text"
                                                errorMessage={this.props.t("Enter Client ID number")}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={this.state.indie.id_number || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6 ">
                                            <FormGroup className="mb-4">
                                              <Label> {this.props.t("ID Expiration date")}</Label>
                                              <AvField
                                                name="id_expiration_date"
                                                placeholder={this.props.t("ID Expiration date")}
                                                type="date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={Moment(this.state.indie.id_expiration_date).format('YYYY-MM-DD') || ""}
                                              />

                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="notes"
                                                label={this.props.t("Notes")}
                                                type="textarea"
                                                rows="1"
                                                placeholder={this.props.t("Notes")}
                                                value={this.state.indie.notes || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>



                                        {this.state.isShow ? null :
                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  {this.props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        }

                                      </AvForm>
                                    }
                                  </ModalBody>
                                </Modal>

                                <Modal
                                  isOpen={this.state.modalLog}
                                  className={this.props.className}
                                  size="lg"
                                >
                                  <ModalHeader toggle={this.toggleLog} tag="h4">
                                    {this.props.t("Booking history")}
                                  </ModalHeader>
                                  <ModalBody>
                                    {loadingLog ?
                                      <div className="text-center">
                                        <Row className="justify-content-center mt-5">
                                          <Col sm="4">
                                            <div>
                                              <div >
                                                <div className="spinner-chase">
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div> :
                                      (clientLog.length !== 0 ?
                                        <div>
                                          <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                            <Table className="table mb-0">
                                              <thead>
                                                <tr>
                                                  <th>{this.props.t("Vehicle")}</th>
                                                  <th>{this.props.t("Pickup date")}</th>
                                                  <th>{this.props.t("Return date")}</th>
                                                  <th>{this.props.t("Days")}</th>
                                                  <th>{this.props.t("Total amount")}</th>
                                                  <th>{this.props.t("Status")}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {clientLog.map((log, index) => (
                                                  <tr key={index}>
                                                    <th scope="row">{log.vehicle}</th>
                                                    <td>{log.pickup_date}</td>
                                                    <td>{log.return_date}</td>
                                                    <td>{log.days}</td>
                                                    <td>{log.total_amount}{" "}{this.props.t("MAD")}</td>
                                                    <td>{log.status}</td>
                                                  </tr>
                                                ))}

                                              </tbody>
                                            </Table>
                                          </div>

                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user mt-3"
                                                  onClick={() => ExportLogToExcel()}
                                                >
                                                  <i className="mdi mdi-microsoft-excel font-size-16  me-1" />{" "}{this.props.t("Export")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                        : <div className="text-center mt-4"><div className="mb-4">
                                          <i className="bx bx-history text-primary display-4" style={{ lineHeight: '1' }} />

                                        </div><h4>{this.props.t("No bookings yet")}</h4>
                                          <p className="mb-4">{this.props.t("Client booking history is displayed here.")}</p>


                                        </div>)
                                    }
                                  </ModalBody>
                                </Modal>
                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteIndie(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}
                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}

                              </div>
                              {/* <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div> */}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Indie.propTypes = {
  indies: PropTypes.array,
  companies: PropTypes.array,
  onGetIndie: PropTypes.func,
  onGetCompany: PropTypes.func,
  onAddNewIndie: PropTypes.func,
  onDeleteIndie: PropTypes.func,
  onUpdateIndie: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  indies: state.clients.indies,
  companies: state.clients.companies
})

const mapDispatchToProps = dispatch => ({
  onGetIndie: () => dispatch(getIndie()),
  onAddNewIndie: indie => dispatch(addNewIndie(indie)),
  onUpdateIndie: indie => dispatch(updateIndie(indie)),
  onDeleteIndie: indie => dispatch(deleteIndie(indie)),
  onGetCompany: () => dispatch(getCompany()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Indie)))

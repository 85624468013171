import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Badge,
  Card,
  CardImg,
  CardBody,
  Col, Row,
  Media,
  CardFooter,
  Button
} from "reactstrap"
import { vehicleImages } from "assets/images/vehicle"
import StarRatings from "react-star-ratings"

const CardVehicle = ({ vehicles }) => {
  return (
    <React.Fragment>
      {map(vehicles, (vehicle, dkey) => (
        
        <Col xl="2" lg="3" md="4" sm="5" xs="12">
          <Card className="text-center" >

            <CardBody>

              <div className="text-end mt-0 mb-2">
                <Badge
                  className={`text-end font-size-10 badge-soft-${vehicle.status === 'available' ? 'success' 
                    : vehicle.status === 'rented' ? 'danger' 
                    : vehicle.status === 'unavailable' ? 'warning' 
                    : 'secondary'}`}
                  pill
                >
                  {vehicle.status}

                </Badge>
              </div>

                  <div className="avatar-md mx-auto mb-4">
                    <span className="avatar-title rounded-circle  font-size-24" style={{ backgroundColor: '#d9a3ad', color: '#FFFFFF' }}  >
                      {vehicle.make.toUpperCase().charAt(0)}
                    </span>
                  </div>
               

              <h5 className="font-size-15 mb-0">
                <Link to="#" className="text-dark">
                  {vehicle.make.toUpperCase() + " " + vehicle.model.toUpperCase()}
                  <br/>
                  {vehicle.year.toUpperCase()}
                </Link>

              </h5>
              <p className="text-muted font-size-12 mt-2 mb-0 ">{" ( " + vehicle.plate_number + " )"}</p>
              
            </CardBody>
            <CardFooter className="text-center bg-transparent border-top ">
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded mb-2 mt-2"
                onClick={() => this.toggle()}
              >
                {"Maintenance"}{" "}<i className="mdi mdi-arrow-right ms-1" />
              </Button>
            </CardFooter>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

CardVehicle.propTypes = {
  vehicles: PropTypes.array
}

export default CardVehicle

import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Countdown
import Countdown from "react-countdown"

class Section extends Component {
  constructor() {
    super()
    this.renderer.bind(this)
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>You are good to go!</span>
    } else {
      // Render a countdown
      return (
        <>
          <div className="coming-box">
            {days}
            <span>Days</span>
          </div>
          <div className="coming-box">
            {hours}
            <span>Hours</span>
          </div>
          <div className="coming-box">
            {minutes}
            <span>Minutes</span>
          </div>
          <div className="coming-box">
            {seconds}
            <span>Seconds</span>
          </div>
        </>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="section hero-section bg-ico-hero" id="home">
          <div className="bg-overlay bg-primary" />
          <Container>
            <Row className="align-items-center">
              <Col lg="5">
                <div className="text-white-50">
                  <h1 className="text-white font-weight-semibold mb-3 hero-title">
                    Plan your trip now
                  </h1>
                  <h2 className="text-white font-weight-semibold mb-3 hero-title">
                    Save <span>big</span> with our car rental
                  </h2>
                  <p className="font-size-14">
                    To contribute to positive change and achieve our sustainability goals with many extraordinary
                  </p>

                  <div className="button-items mt-4">
                    <Link to="#" className="btn btn-success">
                      Book ride
                    </Link>{" "}
                    <Link to="#" className="btn btn-light">
                      Learn more
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg="5" md="8" sm="10" className="ms-lg-auto">

              </Col>
            </Row>
            
          </Container>
        </section>
      </React.Fragment>
    )
  }
}

export default Section

import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup
} from "reactstrap";
import 'firebase/auth';
import SweetAlert from "react-bootstrap-sweetalert"
import { v4 as uuid } from "uuid"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
import imageCompression from 'browser-image-compression';
import ConnectDrive from './ConnectDrive';
import geohash from "ngeohash";

import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';
import * as moment from 'moment';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { collect } from 'underscore';
import { icon } from 'leaflet';
import { GoogleAuth } from 'google-auth-library';
import { withTranslation } from "react-i18next"
const LoadingContainer = () => <div>Loading...</div>

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_id: "",
      isAdmin: false,
      account_email: "",
      //account_user: [],
      email: "",
      name: "",
      idx: 1,
      user: [],
      backingUp: false,
      submitLoading: false,
      imageLoading: false,
      modal: false,
      isReset: false,
      isResetUser: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      mapmodal: false,
      userAddress: null,
      userCity: null,
      userState: null,
      addressLoading: false,
      markerTitle: "The marker`s title will appear as a tooltip.",
      markerName: "Medios",
      markerPosition: { lat: 37.778519, lng: -122.40564 }
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handlePWChange = this.handlePWChange.bind(this)
    this.handlePWSubmit = this.handlePWSubmit.bind(this)
    this.handlePWSubmitUser = this.handlePWSubmitUser.bind(this)

    this.toggleReset = this.toggleReset.bind(this)
    this.toggleResetUser = this.toggleResetUser.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleViewModal = this.toggleViewModal.bind(this)
    this.getLocation = this.getLocation.bind(this)
    this.getCoordinates = this.getCoordinates.bind(this)
    this.getUserAddress = this.getUserAddress.bind(this)
    this.onClickMarker = this.onClickMarker.bind(this)
    this.handleEditPicture = this.handleEditPicture.bind(this)
  }

  onClickMarker(t, map, coord) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    this.getUserAddress(lat, lng)
    this.setState({

      markerTitle: "",
      markerName: "",
      markerPosition: { lat, lng }

    });

  }
  toggleViewModal = () => {
    //this.getLocation()
    this.setState(prevState => ({
      mapmodal: !prevState.mapmodal,
    }))
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError)
    } else {
      alert(this.props.t("geolocation is not supported"))
    }
  }
  getCoordinates(position) {
    //console.log(position)
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      markerPosition: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    })
    this.getUserAddress(position.coords.latitude, position.coords.longitude)
  }
  handleLocationError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        toast.error(this.props.t("User denied the request for Geolocation."))
        break;
      case error.POSITION_UNAVAILABLE:
        toast.error(this.props.t("Location information is unavailable."))
        break;
      case error.TIMEOUT:
        toast.error(this.props.t("The request to get user location timed out."))
        break;
      case error.UNKNOWN_ERROR:
        toast.error(this.props.t("An unknown error occurred."))
        break;
      default:
        toast.error(this.props.t("An unknown error occurred."))
    }
  }
  getUserAddress(lat, lng) {
    //console.log("get user address", lat, lng)
    var key = "AIzaSyANuYDpzsSpPUtQHTGJPoBz9EKWelMNNtg"
    this.setState({
      addressLoading: true
    })
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${key}`)
      .then(response => response.json())
      .then(data => {
        //console.log(data.results)
        this.setState({
          addressLoading: false
        })
        if (data.results[0]) {

          for (var i = 0; i < data.results.length; i++) {
            if (data.results[i].types[0] === "route") {
              var address = data.results[i].formatted_address;
              //console.log(address)
              this.setState({
                userAddress: address
              })
            };
            if (data.results[i].types[0] === "locality") {
              var city = data.results[i].address_components[0].short_name;
              var state = data.results[i].address_components[1].short_name;
              // console.log(city)
              //console.log(state)
              this.setState({
                userCity: city,
                userState: state,

              })
            };
          };
        };
      }
      )
      .catch(error => alert("error" + error))
  }

  toggleReset() {
    this.setState(prevState => ({
      isReset: !prevState.isReset,
    }))
  }
  toggleResetUser() {
    this.setState(prevState => ({
      isResetUser: !prevState.isResetUser,
    }))
  }
  handlePWChange = () => {

    this.toggle()
  }
  resetPassword = (email) => {
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        this.setState({
          success_dlg: true,
          dynamic_title: "Link sent",
          dynamic_description: "An email with password reset link has been sent",
        })
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // console.log(errorCode, errorMessage)
        toast.error(this.props.t("Cannot sent email"))
        // ..
      });
  }
  handlePWSubmit() {
    this.resetPassword(this.state.user.email)
    this.toggleReset()
  }
  handlePWSubmitUser() {
    this.resetPassword(this.state.user.email)
    this.toggleResetUser()
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  getUser = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userUsersCollectionRef = usersCollection.doc(userId)
            const userData = userDoc.data();
            this.setState({
              user: userData,
              user_id: userId
            })

            this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
              this.setState({
                image_url: snapshot.data().image_url,
              })
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });

  }
  // handleValidSubmit
  handleValidSubmit(event, values) {
    //this.props.editProfile(values)
    const updateProfile = {
      id: this.state.user.id,
      // username: values.username.trim(),
      company_name: values.company_name.trim(),
      phone: values.phone.trim(),
      address: values.address.trim(),
      city: values.city.trim(),
      state: values.state.trim(),
      description: values.description.trim(),
      website: values.website.trim(),
      rc: values.rc.trim(),
      patente: values.patente.trim(),
      id_fiscal: values.id_fiscal.trim(),
      ice: values.ice.trim(),
      tp: values.tp.trim()
    }
    //console.log(this.state.user)
    //console.log(updateProfile)
    if (/* this.state.user.username == updateProfile.username
      && */ this.state.user.company_name == updateProfile.company_name
      && this.state.user.phone == updateProfile.phone
      && this.state.user.address == updateProfile.address
      && this.state.user.city == updateProfile.city
      && this.state.user.state == updateProfile.state
      && this.state.user.description == updateProfile.description
      && this.state.user.website == updateProfile.website
      && this.state.user.rc == updateProfile.rc
      && this.state.user.ice == updateProfile.ice
      && this.state.user.tp == updateProfile.tp
      && this.state.user.patente == updateProfile.patente
      && this.state.user.id_fiscal == updateProfile.id_fiscal) {
      this.setState({ submitLoading: false })
    } else {
      this.editProfile(updateProfile)
    }

  }
  addNewTimeline = async (timeline) => {
    try {
      const { user_id, user } = this.state;
      //console.log(timeline, user)
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = user.isAdmin ? 'Admin' : `${user.first_name} ${user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      //console.error("Error adding timeline:", error);
    }
  }
  editProfile = async (profile) => {

    this.setState({ submitLoading: true })
    const usersCollection = firebase.firestore().collection("users")
    //const collection = firebase.firestore().collection("usernames")
    const timeline = {
      statusTitle: "Profile infos updated",
      iconClass: "bx bx-pencil h2 text-success",
      description: "Profile infos updated",
      data: "",
      time: new Date().getTime(),

    }
    usersCollection
      .get().then(result => {

        var userKayn = false
        var phoneKayn = false
        result.forEach(async doc => {
          if (doc.data().id !== profile.id) {
            /* if (doc.data().username === profile.username) {
              userKayn = true
            } */
            if (doc.data().phone === profile.phone) {
              phoneKayn = true
            }

          }
        })

        //console.log(userKayn, phoneKayn)
        /*  if (!userKayn) {
           if (!phoneKayn) { */
        usersCollection.doc(profile.id).update({
          // username: profile.username,
          company_name: profile.company_name,
          phone: profile.phone,
          address: profile.address,
          city: profile.city,
          state: profile.state,
          description: profile.description,
          website: profile.website,
          rc: profile.rc,
          patente: profile.patente,
          id_fiscal: profile.id_fiscal,
          ice: profile.ice,
          tp: profile.tp

        }).then(() => {

          //console.log("Document Updated!");
          toast.success(this.props.t("Profile  Updated!"))
          this.addNewTimeline(timeline)
          this.setState({ submitLoading: false })

        }).catch((error) => {
          // console.error("Error updating document: ", error);
          toast.error(this.props.t("Error updating document"))
          this.setState({ submitLoading: false })
        });
        /* } else {
          console.error("Phone number already exist ");
          toast.error("Phone number already exist ")
          this.setState({ submitLoading: false })
        }
      } else {
        console.error("Username already exist ");
        toast.error("Username already exist ")
        this.setState({ submitLoading: false })
      } */
      })

  }

  changeLocation = async (lat, lng, address, city, state) => {

    this.setState({ submitLoading: true })
    const usersCollection = firebase.firestore().collection("users")
    //const collection = firebase.firestore().collection("usernames")
    const timeline = {
      statusTitle: "Location changed",
      iconClass: "bx bx-pencil h2 text-success",
      description: "Agency localisation changed",
      data: "",
      time: new Date().getTime(),

    }
    usersCollection
      .get().then(result => {


        if (lat != null || lng != null) {
          const hash = geohash.encode(lat, lng);
          if (address != null || city != null || state != null) {
            usersCollection.doc(this.state.user.id).update({

              address: address,
              city: city,
              state: state,
              lat: lat,
              lng: lng,
              position_hash: hash

            }).then(() => {

              //console.log("Document Updated!");
              toast.success(this.props.t("Location  Changed!"))
              this.addNewTimeline(timeline)
              this.setState({ submitLoading: false })
              this.toggleViewModal()

            }).catch((error) => {
              //console.error("Error updating document: ", error);
              toast.error(this.props.t("Error Changing location"))
              this.setState({ submitLoading: false })
              this.toggleViewModal()
            });
          } else {
            // console.error("Cannot get location ");
            toast.error(this.props.t("Cannot get location"))
            this.setState({ submitLoading: false })
            this.toggleViewModal()
          }
        } else {
          //console.error("Cannot get Address ");
          toast.error(this.props.t("cannot get Address"))
          this.toggleViewModal()
          this.setState({ submitLoading: false })
        }
      })




  }



  componentDidMount() {
    const { user } = this.state
    if (user && !user.length) {
      //onGetStaff()
      this.getUser()

    }

    this.setState({ user })
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        })
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid })
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (prevState.user.verification_done !== this.state.user.verification_done && this.state.user.verification_done == true) {

      // console.log("state changed ", this.state.user.verification_done)
      // toast.success("Image verification done")


    }

    if (this.props !== prevProps) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      //console.log(obj)
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        })
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid })
      }
      setTimeout(() => {
        this.props.resetProfileFlag();
      }, 3000);
    }
  }
  handleImageChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const image = event.target.files[0]
    // console.log("file ", event.target.files[0])
    this.setState({ imageLoading: true })
    const user = this.state.user.id
    const iid = uuid()
    const img = firebase.storage().ref(firebase.auth().currentUser.uid + "/userspp/").child(iid + ".png")
    const doc = firebase.firestore().collection("users").doc(user)
    var metadata = {
      contentType: 'image/png',
    };


    if (image !== undefined) {
      var validSize = true
      // console.log(image.size / 1024 / 1024 + "Mo")
      if (image.size / 1024 / 1024 > 2) {
        validSize = false
      } else {
        validSize = true
      }
    }

    const timeline = {
      statusTitle: "Profile picture changed",
      iconClass: "bx bx-image-add h2 text-success",
      description: "Profile picture changed",
      data: "",
      time: new Date().getTime(),

    }
    //send to server
    if (image != undefined) {
      if (validSize) {

        img.put(image, metadata).then((snapshot) => {

          snapshot.ref.getDownloadURL().then(url => {
            doc.update({ image_url: url, image_path: img.fullPath, verification_done: false })
            //console.log("Image uploaded ");
            toast.success(this.props.t("Profile image uploaded"))
            this.addNewTimeline(timeline)
            this.setState({ imageLoading: false })
          });
        }).catch((error) => {
          //console.log("Error uploading image " + error);
          toast.error(this.props.t("Error uploading image"))
          this.setState({ imageLoading: false })
        });


      } else {
        //console.log("Image size is too big");
        toast.error(this.props.t("Image size is too big"))
        this.setState({ imageLoading: false })
      }
    } else {
      // console.log("Error uploading image");
      toast.error(this.props.t("Error uploading image"))
      this.setState({ imageLoading: false })
    }
  }
  handleEditPicture = () => {
    var fileInput = document.getElementById("imageInput")
    //console.log(fileInput)
    fileInput.click()
  }

  handleBackup = async () => {
    // Assuming you have access to the userId, either from props or state
    const userId = this.state.user.id;

    if (!userId) {
      console.error('User ID not available');
      return;
    }
    this.setState({ backingUp: true });

    try {
      const backup = firebase.app().functions('europe-west1').httpsCallable('backup');
      const result = await backup({ userId: userId })
      // Create a Blob from the base64 data
      const byteCharacters = atob(result.data.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: result.data.contentType });
      var check = moment(new Date()).format('DD-MM-YYYY');
      // Create a download link and click it
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `medios_backup(${check}).zip`;
      link.click();

      toast.success(this.props.t("Backup downloaded successfully"));
    } catch (error) {
      console.error('Error:', error);
      toast.error(this.props.t("Failed to create the backup."));
    } finally {
      this.setState({ backingUp: false });
    }
    /*try {
      const functionUrl = 'https://europe-west1-meanz-3960c.cloudfunctions.net/backup';
      const response = await fetch(`${functionUrl}?userId=${userId}`, {
        method: 'GET',
      });

       if (response.ok) {
        var check = moment(new Date()).format('DD-MM-YYYY');
        //console.log(check)
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `medios_backup(${check}).zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        toast.error(this.props.t("Backup failed"))
        console.error('Backup failed');
        // You might want to show an error message to the user here
      } 
    } catch (error) {
      toast.error(this.props.t("Backup failed"))
      console.error('Error during backup:', error);
      // You might want to show an error message to the user here
    } finally {
      this.setState({ backingUp: false });
    }*/

  };
  render() {
    var av = ""
    const { user, image_url } = this.state
    const { imageLoading } = this.state
    if (user.image_url === "" || image_url === "") {
      av = avatar;
    } else {
      av = user.image_url || image_url;
    }
    // console.log(user)
    //(image_url)
    return (
      <React.Fragment>
        <MetaTags>
          <title>{this.props.t("Profile | Medios - Car Rental Management System")}</title>
        </MetaTags>
        <Modal
          isOpen={this.state.mapmodal}
          size='md'
        >
          <ModalHeader toggle={this.toggleViewModal} tag="h4">
            {this.props.t("Backup your data")}
          </ModalHeader>
          <ModalBody>


            <h5 className="mt-1 text-center">{this.props.t("Manage your data backup locally or in the cloud.")}</h5>

            <br />
            <Row className="text-center">
              <Col >
                <h6 className="card-title-desc">
                  {this.props.t("Download all your data to your local device")}
                </h6>
              </Col>
            </Row>
            <Row className=" text-center mb-4 mt-0">
              <Col >
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={this.handleBackup}
                >
                  <i className="bx bx-download font-size-16 align-middle me-2"></i>
                  {this.props.t("Download to device")}
                </button>
              </Col >
            </Row>
            <hr />
            <Row className="text-center">
              <Col >
                <h6 className="card-title-desc">
                  {this.props.t("Keep your data safe in your Google Drive")}
                </h6>
              </Col>
            </Row>
            <Row className=" text-center mb-2 mt-0">
              <Col >
                <ConnectDrive userId={user.id} />
              </Col >
            </Row>

            <Row className="text-center">
              <p className="card-title-desc">
                {this.props.t("This is beneficial for working on your data offline and ensures you always have a reliable backup to safeguard your information.")}
              </p>
            </Row>


          </ModalBody>
        </Modal>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title={this.props.t("Medios")} breadcrumbItem={this.props.t("Profile")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row>
              <Col lg="12">
                {/* {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null} */}
                <Modal isOpen={this.state.submitLoading}>
                  <ModalBody>
                    <Col>
                      <div>
                        <div >
                          <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                    </Col>

                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.backingUp}>
                  <ModalBody>
                    <Col>
                      <div>
                        <div >
                          <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h6 className='text-center'>{this.props.t("Downloading backup...")}</h6>
                    </Col>

                  </ModalBody>
                </Modal>
                {this.state.isResetUser ? (
                  <SweetAlert
                    title={this.props.t("Are you sure?")}
                    warning
                    showCancel
                    confirmButtonText={this.props.t("Yes, reset password!")}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlePWSubmitUser}
                    onCancel={() =>
                      this.setState({
                        isResetUser: false,
                      })
                    }
                  >
                    {this.props.t("Reset your password!")}
                  </SweetAlert>
                ) : null}
                {this.state.isReset ? (
                  <SweetAlert
                    title={this.props.t("Are you sure?")}
                    warning
                    showCancel
                    confirmButtonText={this.props.t("Yes, reset password!")}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlePWSubmit}
                    onCancel={() =>
                      this.setState({
                        isReset: false,
                      })
                    }
                  >
                    {this.props.t("Reset your password!")}
                  </SweetAlert>
                ) : null}
                <Card>
                  <CardBody>

                    {user && user.isAdmin ?
                      <Row >
                        <Col md="2">
                          {imageLoading ?
                            <i className="bx bx-loader bx-spin bx-md" />
                            :
                            <img
                              src={av}
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail"
                              onClick={this.handleEditPicture}
                            />
                          }

                          <input
                            id="imageInput"
                            type="file"
                            accept="image/png, image/jpeg"
                            hidden={true}
                            onChange={this.handleImageChange.bind(this)}
                          />
                        </Col>
                        <Col md="6">
                          <h5>{user.company_name}</h5>
                          {/* <p className="mb-0">@{this.state.user.username}</p> */}
                          <p className="mb-0">{user.email}</p>
                          <Link to="#" onClick={this.handleEditPicture}>
                            {this.props.t("Edit profile picture")}
                          </Link>
                        </Col>
                        {user.isAdmin ? <Col className="text-end mt-4">
                          <Button color="secondary" onClick={() => this.toggleViewModal()}>
                            <i className="bx bx-data font-size-16 align-middle me-2"></i>
                            {this.props.t("Backup your data")}
                          </Button>
                        </Col> : null}

                      </Row>
                      :

                      <Row >
                        <Col md="2">
                          {imageLoading ?
                            <i className="bx bx-loader bx-spin bx-md" />
                            :
                            <img
                              src={av}
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail"
                            /* onClick={this.handleEditPicture} */
                            />
                          }

                          {/* <input
                          id="imageInput"
                          type="file"
                          accept="image/png, image/jpeg"
                          hidden={true}
                          onChange={this.handleImageChange.bind(this)}
                        /> */}
                        </Col>
                        <Col md="6">
                          <h5>{user.first_name + " " + user.last_name}</h5>
                          {/* <p className="mb-0">@{this.state.user.username}</p> */}
                          <p className="mb-0">{user.email}</p>
                          {/* <Link to="#" onClick={this.handleEditPicture}>
                          Edit profile picture
                        </Link> */}
                        </Col>
                        <Col className="text-end mt-4">
                          {/* <Button color="light" onClick={() => this.toggleViewModal()}>
                          <i className="bx bx-map font-size-16 align-middle me-2"></i>
                          Change localisation
                        </Button> */}
                        </Col>
                      </Row>
                    }

                    {/*  <div className="me-3">
                        
                      </div>

                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          

                        </div>
                      </div>


                      
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">{this.props.t("Account informations")}</h4>

            <Card>
              <CardBody>
                {user.isAdmin ?
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      this.handleValidSubmit(e, v)
                    }}
                  >
                    <Row>

                      {/* <Col md="5">
                      <FormGroup className="mb-3">
                        <Label>{this.props.t("Username")}</Label>

                        <AvField
                          name="username"
                          placeholder={this.props.t("Username")}
                          type="text"
                          errorMessage={this.props.t("Enter Username")}
                          validate={{ required: { value: true } }}
                          value={this.state.user.username}
                          disabled={true}
                        />


                      </FormGroup>
                    </Col> */}
                      <Col md="5">
                        <FormGroup className="mb-3">
                          <Label >
                            {this.props.t("Email")}
                          </Label>
                          <AvField
                            name="email"
                            placeholder={this.props.t("E-mail")}
                            type="text"
                            errorMessage={this.props.t("Enter a valid E-mail")}
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            disabled={true}
                            value={this.state.user.email || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label>
                            {this.props.t("Company name")}
                          </Label>
                          <AvField
                            name="company_name"
                            placeholder={this.props.t("Company name")}
                            type="text"
                            errorMessage={this.props.t("Enter company name")}
                            className="form-control"
                            validate={{
                              required: { value: true }
                            }}
                            value={this.state.user.company_name || ""}
                          />

                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label> {this.props.t("Phone number")}</Label>
                          <AvField
                            name="phone"
                            placeholder={this.props.t("Phone Number")}
                            type="text"
                            errorMessage={this.props.t("Enter a valid phone number")}
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: '^(0|\\+)\\d{9,14}$',
                                errorMessage: this.props.t("Enter a valid phone number")
                              },
                            }}
                            disabled={true}
                            value={this.state.user.phone || ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>


                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <AvField
                            name="address"
                            label={this.props.t("Address")}
                            type="textarea"
                            errorMessage={this.props.t("Invalid Address")}
                            rows="1"
                            placeholder={this.props.t("Address")}
                            value={this.state.user.address || this.state.userAddress || ""}
                          />
                        </FormGroup>
                      </Col>

                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <AvField
                            name="city"
                            label={this.props.t("City")}
                            type="text"
                            errorMessage="Invalid City"
                            placeholder={this.props.t("City")}
                            value={this.state.user.city || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <AvField
                            name="state"
                            label={this.props.t("Region")}
                            type="text"
                            
                            placeholder={this.props.t("region")}
                            value={this.state.user.state || ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="rc"
                            label={this.props.t("Trade register number")}
                            type="text"
                            placeholder={this.props.t("Trade register number")}
                            value={this.state.user.rc || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="patente"
                            label={this.props.t("License")}
                            type="text"
                            placeholder={this.props.t("License")}
                            value={this.state.user.patente || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="id_fiscal"
                            label={this.props.t("Tax identification number")}
                            type="text"
                            placeholder={this.props.t("Tax identification number")}
                            value={this.state.user.id_fiscal || ""}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="ice"
                            label={this.props.t("ICE")}
                            type="text"
                            placeholder={this.props.t("N ICE")}
                            value={this.state.user.ice || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="tp"
                            label={this.props.t("Business tax")}
                            type="text"
                            placeholder={this.props.t("N T.P")}
                            value={this.state.user.tp || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="website"
                            label={this.props.t("Website")}
                            type="text"
                            placeholder={this.props.t("Website")}
                            value={this.state.user.website || ""}
                          />
                        </FormGroup>
                      </Col>


                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <AvField
                            name="description"
                            label={this.props.t("Description")}
                            type="textarea"
                            rows="2"
                            placeholder={this.props.t("Description")}
                            value={this.state.user.description || ""}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row >
                      <Col className="text-start mt-4">
                        <Button color="danger" onClick={() => this.setState({ isReset: true })}>
                          {this.props.t("Change password")}
                        </Button>
                      </Col>
                      {user.isAdmin ?
                        <Col className="text-center mt-4">
                          {/* <Button color="info" onClick={this.handleBackup}>
                            {this.props.t("backup")}
                          </Button> */}
                          {/* <ConnectDrive userId={user.id} /> */}
                        </Col> : null}
                      <Col className="text-end mt-4">
                        <Button type="submit" color="success">
                          {this.props.t("Update Profile")}
                        </Button>
                        {/* <Link to={"/agency/"+this.state.user.username} color="success">
                        test
                      </Link> */}
                      </Col>

                    </Row>
                  </AvForm>
                  :

                  <AvForm
                    className="form-horizontal"
                  /* onValidSubmit={(e, v) => {
                    this.handleValidSubmitUser(e, v)
                  }} */
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label >
                            {this.props.t("Email")}
                          </Label>
                          <AvField
                            name="email"
                            placeholder={this.props.t("E-mail")}
                            type="text"
                            errorMessage={this.props.t("Enter a valid E-mail")}
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            disabled={true}
                            value={user.email || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label>{this.props.t("First name")}</Label>

                          <AvField
                            name="first_name"
                            placeholder={this.props.t("First name")}
                            type="text"
                            errorMessage={this.props.t("Enter first name")}
                            validate={{ required: { value: true } }}
                            value={user.first_name}
                            disabled={true}
                          />


                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label>{this.props.t("Last name")}</Label>

                          <AvField
                            name="last_name"
                            placeholder={this.props.t("Last name")}
                            type="text"
                            errorMessage={this.props.t("Enter last name")}
                            validate={{ required: { value: true } }}
                            value={user.last_name}
                            disabled={true}
                          />


                        </FormGroup>
                      </Col>
                    </Row>


                    <Row >
                      <Col className="text-start mt-4">
                        <Button color="danger" onClick={() => this.setState({ isResetUser: true })}>
                          {this.props.t("Change password")}
                        </Button>
                      </Col>


                    </Row>
                  </AvForm>

                }

              </CardBody>
            </Card>
            {/* <h4 className="card-title mb-4">{this.props.t("Backup your data")}</h4>
            <Card>
                  <CardBody>
                  <h5 className="card-title-desc">
                      Manage your data backup locally or in the cloud.
                    </h5>
                    <li className="label">
                                              Donec sodales sagittis
                                            </li>
                  </CardBody>
                  </Card> */}
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  t: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
  google: PropTypes.object
}

const mapStateToProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(
    withTranslation()(UserProfile)
  )
)


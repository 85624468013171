import React, { Component } from "react"
import PropTypes from 'prop-types'
import { withTranslation } from "react-i18next"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import ApexRadial from "./ApexRadial"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import * as moment from 'moment';

class MonthlyEarning extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rev: 0,
      d: 0,
    }
  }
  componentDidMount() {

    this.getReservation()

  }

  getReservation = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations");

            this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
              let reservations = [];
              let d = []
              snapshot.docs.forEach(doc => {
                reservations.push(doc.data().amount)
                d.push(doc.data())
              });
              this.setState({
                //loading: false,
                rev: reservations,
                d: d,
              })

            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              rev: 0
            });
          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          rev: 0
        });
      }
    });
  }
  render() {

    const { rev, d } = this.state
    const date = moment(new Date());
    const dow = date.date();

    var tmsf = 0
    var pmsf = 0

    var now = new Date();
    /* var month = moment(now).month()
    var year = moment(now).year() */


    var check = moment(now, 'YYYY/MM/DD');

    var mo = check.format('M');
    var ye = check.format('YYYY');
    var month = parseInt(mo) - 1;
    var year = parseInt(ye);
    //console.log(d)
    // //console.log(now.getDate(),month, year)
    var pssd = 0
    var peed = 0
    /* if(month==0){
        pssd=new Date(year-1,11,0)
         peed=new Date(year-1,11,now.getDate()+1)
         //console.log("dkhel")
    }else{ */
    pssd = new Date(year, month - 1, 0)
    // peed=new Date(year,month-1,now.getDate()+1)
    peed = new Date(year, month, 0)
    //}
    //console.log(pssd,peed)
    var ssd = new Date(year, month, 0)
    //var eed=new Date(year,month,now.getDate()+1)
    var eed = new Date(year, month + 1, 0)

    // //console.log("eed",ssd)
    /*  for (var i = 0; i < d.length; i++) {
       
       if (d[i].pd >= ssd.getTime() && d[i].pd <= eed.getTime()) {
         tmsf+=d[i].total_amount
       //  //console.log(d[i])
       }
       //console.log(d[i].pd >= pssd.getTime() && d[i].pd <= peed.getTime()  )
       if (d[i].pd >= pssd.getTime() && d[i].pd <= peed.getTime()) {
         pmsf+=d[i].total_amount
         ////console.log(d[i])
       }
     } */
    /* var pchange=0
    if(pmsf===0){
      pchange=100
    }else{
      pchange=((tmsf-pmsf)/pmsf)*100
    } */
    const currentDate = moment().format('YYYY-MM');
    //console.log(currentDate);
    const previousMonth = moment().subtract(1, 'months').format('YYYY-MM');
   // console.log(previousMonth);

    var pm = 0, tm = 0
    for (var i = 0; i < d.length; i++) {

      if (moment(moment(previousMonth, 'YYYY-MM').valueOf()).isSame(moment(d[i].pd), 'month')) {
        pm += d[i].total_amount
        //  //console.log(d[i])
      }
      if (moment(moment(currentDate, 'YYYY-MM').valueOf()).isSame(moment(d[i].pd), 'month')) {
        tm += d[i].total_amount
        //  //console.log(d[i])
      }

    }
    //console.log(pm)
    //console.log("pchange",pchange)
    /* //console.log("tmsf",tmsf)
    console.log("pmsf",pmsf) */

    function calculateGrowthPercentage(previousMonth, currentMonth) {
      if (previousMonth === 0) {
        // Handle division by zero
        return currentMonth > 0 ? Infinity : 0;
      }

      const growthRate = (currentMonth - previousMonth) / previousMonth;
      const growthPercentage = growthRate * 100;

      return growthPercentage;
    }

    // Your data
    const previousMonthEarnings = pm;
    const currentMonthEarnings = tm;

    // Calculate growth percentage
    const growthPercentage = calculateGrowthPercentage(previousMonthEarnings, currentMonthEarnings);

    // Round to 2 decimal places
    const pchange = growthPercentage;

   // console.log(`Growth Percentage: ${pchange}%`);
    //console.log(pm, tm)
    return (
      <React.Fragment>
        {" "}
        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">{this.props.t("Monthly Earning")}</CardTitle>
            <Row>
              <Col sm="6">
                <p className="text-muted">{this.props.t("This month so far")}</p>
                <h4>{tm} DHs</h4>

                {pchange >= 0 ?
                  <p className="text-muted">

                    <span className="text-success me-2">
                      {" "}
                      {pchange.toFixed(2)} % <i className="mdi mdi-arrow-up"></i>{" "}
                    </span>{" "}
                    {this.props.t("From previous month")}
                  </p> :
                  pchange < 0 ? <p className="text-muted">

                    <span className="text-danger me-2">
                      {" "}
                      {pchange.toFixed(2)} % <i className="mdi mdi-arrow-down"></i>{" "}
                    </span>{" "}
                    {this.props.t("From previous month")}
                  </p>
                    :
                    <p className="text-muted">

                      <span className="text-warning me-2">
                        {" "}
                        {this.props.t("No previous data")} {" "}
                      </span>{" "}
                      {this.props.t("From previous period")}
                    </p>
                }


                <div className="mt-4">
                  <Link
                    to="/rental-calendar"
                    className="btn btn-primary btn-sm"
                  >
                    {this.props.t("Reservations")}  <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </Col>
              <Col sm="6">
                <div className="mt-4 mt-sm-0">
                  <ApexRadial />
                </div>
              </Col>
            </Row>
            {/* <p className="text-muted mb-0">
              {this.props.t("This percentage represents the proportion of reservations made out of all possible dates.")}

            </p> */}
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
MonthlyEarning.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(MonthlyEarning);


import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

//i18n
import { withTranslation } from "react-i18next"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAdmin: false,
      NotifCount: 0,
      reservation_notifs: [],
      repair_notifs: [],
      inssNotifs: [],
      taxNotifs: [],
      visiteNotifs:[],
      oilChangeNotifs: []
    }
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    const { reservation_notifs, inssNotifs, repair_notifs, taxNotifs,visiteNotifs, oilChangeNotifs } = this.state


    if (reservation_notifs && !reservation_notifs.length) {
      this.getNotifs()
    }
    this.setState({ reservation_notifs })


    if (repair_notifs && !repair_notifs.length) {
      this.getRNotifs()
    }
    if (inssNotifs && !inssNotifs.length) {
      this.getInssNotifs()
    }
    this.setState({ inssNotifs })
    if (taxNotifs && !taxNotifs.length) {
      this.getTaxNotifs()
    }
    this.setState({ taxNotifs })
    if (oilChangeNotifs && !oilChangeNotifs.length) {
      this.getOilChangeNotifs()
    }
    if (visiteNotifs && !visiteNotifs.length) {
      this.getVisiteNotifs()
    }
    this.setState({ visiteNotifs })
    this.setState({ oilChangeNotifs })
    this.getUser()
    this.setState({ repair_notifs })
    this.initMenu()
  }
  getUser = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {

            if (userDoc.data().isAdmin) {
              this.setState({
                isAdmin: true
              })

            }
            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          // console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });

  }
  getNotifs = async () => {

    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeNotifs) {
      this.unsubscribeNotifs();
    }

    this.unsubscribeNotifs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userNotifsCollectionRef = usersCollection.doc(userId).collection("notifications").where("viewed", "==", false)
              .where("type", 'in', ['ReservationDone', 'ReservationStart', "ReservationRsoon", "ReservationPsoon"]);

            this.unsubscribeNotifs = userNotifsCollectionRef.onSnapshot(snapshot => {
              let kra = [];
              snapshot.docs.forEach(doc => {
                kra.push(doc.data());
              });

              this.setState({
                /* loading: false, */
                reservation_notifs: kra,
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              /* loading: false, */
              reservation_notifs: []
            });

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          /*  loading: false, */
          reservation_notifs: []
        });
      }
    });


  }

  getRNotifs = async () => {

    this.setState({ loading: true });
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeNotifs) {
      this.unsubscribeNotifs();
    }
    this.unsubscribeNotifs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userNotifsCollectionRef = usersCollection.doc(userId).collection("notifications").where("viewed", "==", false)
              .where("type", 'in', ['RepairEnd', 'RepairStart', "RepairEsoon", "RepairSsoon"])

            this.unsubscribeNotifs = userNotifsCollectionRef.onSnapshot(snapshot => {
              let kra = [];
              snapshot.docs.forEach(doc => {
                kra.push(doc.data());
              });

              this.setState({
                /* loading: false, */
                repair_notifs: kra,
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              /* loading: false, */
              repair_notifs: []
            });

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          /*  loading: false, */
          repair_notifs: []
        });
      }
    });
  }
  getInssNotifs = async () => {

    this.setState({ loading: true });
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeNotifs) {
      this.unsubscribeNotifs();
    }
    this.unsubscribeNotifs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userNotifsCollectionRef = usersCollection.doc(userId).collection("notifications").where("viewed", "==", false)
              .where("type", 'in', ['InssuranceSoon', 'InssuranceExpired'])

            this.unsubscribeNotifs = userNotifsCollectionRef.onSnapshot(snapshot => {
              let kra = [];
              snapshot.docs.forEach(doc => {
                kra.push(doc.data());
              });

              this.setState({
                /* loading: false, */
                inssNotifs: kra,
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              /* loading: false, */
              inssNotifs: []
            });

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          /*  loading: false, */
          inssNotifs: []
        });
      }
    });
  }
  getTaxNotifs = async () => {

    this.setState({ loading: true });
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeNotifs) {
      this.unsubscribeNotifs();
    }
    this.unsubscribeNotifs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userNotifsCollectionRef = usersCollection.doc(userId).collection("notifications").where("viewed", "==", false)
              .where("type", 'in', ['TaxSoon', 'TaxExpired'])

            this.unsubscribeNotifs = userNotifsCollectionRef.onSnapshot(snapshot => {
              let kra = [];

              snapshot.docs.forEach(doc => {
                kra.push(doc.data());
                //console.log(doc.data())
              });

              this.setState({
                /* loading: false, */
                taxNotifs: kra,
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              /* loading: false, */
              taxNotifs: []
            });

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          /*  loading: false, */
          taxNotifs: []
        });
      }
    });
  }
  getVisiteNotifs = async () => {

    this.setState({ loading: true });
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeNotifs) {
      this.unsubscribeNotifs();
    }
    this.unsubscribeNotifs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userNotifsCollectionRef = usersCollection.doc(userId).collection("notifications").where("viewed", "==", false)
              .where("type", 'in', ['VisiteSoon', 'VisiteExpired'])

            this.unsubscribeNotifs = userNotifsCollectionRef.onSnapshot(snapshot => {
              let kra = [];

              snapshot.docs.forEach(doc => {
                kra.push(doc.data());
                //console.log(doc.data())
              });

              this.setState({
                /* loading: false, */
                visiteNotifs: kra,
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              /* loading: false, */
              visiteNotifs: []
            });

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          /*  loading: false, */
          visiteNotifs: []
        });
      }
    });
  }
  getOilChangeNotifs = async () => {

    this.setState({ loading: true });
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeNotifs) {
      this.unsubscribeNotifs();
    }
    this.unsubscribeNotifs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userNotifsCollectionRef = usersCollection.doc(userId).collection("notifications").where("viewed", "==", false)
              .where("type", 'in', ['OilChangeSoon', 'OilChangeToday'])

            this.unsubscribeNotifs = userNotifsCollectionRef.onSnapshot(snapshot => {
              let kra = [];
              snapshot.docs.forEach(doc => {
                kra.push(doc.data());
              });

              this.setState({
                /* loading: false, */
                oilChangeNotifs: kra,
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              /* loading: false, */
              oilChangeNotifs: []
            });

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          /*  loading: false, */
          oilChangeNotifs: []
        });
      }
    });
  }
  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }

  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    const { reservation_notifs, repair_notifs, isAdmin, inssNotifs,visiteNotifs, taxNotifs, oilChangeNotifs } = this.state
    //console.log(visiteNotifs)

    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t('Dashboards')}</li>
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span>{this.props.t('Dashboard')}</span>
                </Link>

              </li>
              <li className="menu-title">{this.props.t('Rental')}</li>

              <li>

                {/* <Link to="/#" className="has-arrow waves-effect">
                
                  <i className="bx bx-spreadsheet"></i>
                  <span>{this.props.t('Rental')} </span>
                  
                </Link> */}

                <Link to="/rental-calendar">
                  {/* {reservation_notifs.length!=0?<span className="badge rounded-pill bg-danger float-end">{reservation_notifs.length}</span>:null} */}
                  <i className="bx bx-calendar"></i>
                  <span>{this.props.t("Calendar")}</span>
                </Link>

              </li>
              <li>
                <Link to="/reservations">
                  <i className="bx bx-spreadsheet"></i>
                  {reservation_notifs.length != 0 ? <span className="badge rounded-pill bg-danger float-end">{reservation_notifs.length}</span> : null}

                  <span>{this.props.t("Reservations")}</span>
                </Link></li>


              {/* <li><Link to="/booking_requests">{this.props.t('Online Reservations')} </Link></li> */}
              <li><Link to="/tariffs"><i className="bx bx-customize"></i><span>{this.props.t('Rates')}</span></Link></li>

              {/* <li className="menu-title">{this.props.t('Documents')}</li>

              <li>
                <Link to="/contracts" className="waves-effect">
                  <i className="bx bx-edit-alt"></i>
                  <span>{this.props.t('Contracts')}</span>
                </Link>
              </li> */}
              <li>
                <Link to="/documents" className="waves-effect">
                  <i className="bx bx-file"></i>
                  <span>{this.props.t('Documents')}</span>
                </Link>

              </li>
              <li>
                <Link to="/quotes" className="waves-effect">
                  <i className="bx bx-receipt"></i>
                  <span>{this.props.t('Quotes')}</span>
                </Link>

              </li>


              <li className="menu-title">{this.props.t('Fleet')}</li>

              <li><Link to="/vehicles"><i className="bx bx-car"></i><span>{this.props.t('Vehicles')}</span></Link></li>
              <li> <Link to="/maintenance">
                {repair_notifs.length+ oilChangeNotifs.length != 0 ? <span className="badge rounded-pill bg-danger float-end">{repair_notifs.length+oilChangeNotifs.length}</span> : null}
                {/* {oilChangeNotifs.length != 0 ? <span className="badge rounded-pill bg-danger float-end">{oilChangeNotifs.length}</span> : null} */}
                <i className="bx bx-wrench"></i>
                <span>{this.props.t("Maintenance")}</span>
              </Link>

              </li>
              <li><Link to="/vignette">
                {taxNotifs.length != 0 ? <span className="badge rounded-pill bg-danger float-end">{taxNotifs.length}</span> : null}
                <i className="bx bx-money"></i>
                <span>{this.props.t('Annual tax')}</span></Link></li>
              <li><Link to="/inssurance">
                {inssNotifs.length != 0 ? <span className="badge rounded-pill bg-danger float-end">{inssNotifs.length}</span> : null}
                <i className="bx bx-paperclip"></i>
                <span>{this.props.t('Inssurance')}</span></Link></li>
                <li><Link to="/technical_inspection">
                {visiteNotifs.length != 0 ? <span className="badge rounded-pill bg-danger float-end">{visiteNotifs.length}</span> : null}
                <i className="bx bx-search-alt"></i>
                <span>{this.props.t('Technical inspection')}</span></Link></li>
              {/* <li><Link to="/inspection">{this.props.t('Inspections')}</Link></li> */}
              <li>
                <Link to="/fines" className="waves-effect">
                  <i className="bx bx-receipt"></i>
                  <span>{this.props.t('Traffic Fines')}</span>
                </Link>

              </li>

              <li className="menu-title">{this.props.t('Extra')}</li>

              <li>
                <Link to="/clients" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{this.props.t('Clients')}</span>
                </Link>

              </li>




              {/* <li>
                <Link to="/chat" className="waves-effect">
                  <i className="bx bx-chat"></i>
                  <span>{this.props.t('Chat')}</span>
                </Link>

              </li> */}
              <li>
                <Link to="/suppliers" className="waves-effect">
                  <i className="bx bx-wrench"></i>
                  <span>{this.props.t('Workshops')}</span>
                </Link>

              </li>
              {isAdmin ? <li>
                <Link to="/staff" className="waves-effect">
                  <i className="bx bx-briefcase"></i>
                  <span>{this.props.t('Staff')}</span>
                </Link>

              </li> : null}




              {/* <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="bx bx-receipt"></i>
                            <span>{this.props.t('Vouchers')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="/#">{this.props.t('Invoices')}</Link></li>
                            <li><Link to="/#">{this.props.t('Reciepts')} </Link></li>
                            <li><Link to="/#">{this.props.t('Purchase Orders')}</Link></li>
                            <li><Link to="/#">{this.props.t('Payments')}</Link></li>
                            <li><Link to="/#">{this.props.t('Traffic Fines')}</Link></li>
                        </ul>
                    </li> */}
              {/*  <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-bar-chart"></i>
                  <span>{this.props.t('Reports')}</span>
                </Link>
                 <ul className="sub-menu" aria-expanded="false">
                  <li><Link to="/report-vehicles">{this.props.t('Vehicles')}</Link></li>
                  <li><Link to="/report-clients">{this.props.t('Clients')} </Link></li>
                  <li><Link to="/performance">{this.props.t('Performance')} </Link></li>

                </ul> 
              </li>*/}
              {/*  <li>
                        <Link to="/chat" className=" waves-effect">
                            <i className="bx bx-chat"></i>
                            <span>{this.props.t('Chat')}</span>
                        </Link>
                        
                    </li> */}
              {/*  <li>
                <Link to="/qrcode" className=" waves-effect">
                  <i className="mdi mdi-qrcode-scan"></i>
                  <span>{this.props.t('Qr code')}</span>
                </Link>

              </li> */}




              {/*   <li>
                        <Link to="/#" className=" waves-effect">
                            <i className="bx bx-dollar"></i>
                            <span>{this.props.t('Expense types')}</span>
                        </Link>
                    </li> */}

              {/* <li>
                <Link to="/#" className=" waves-effect">
                  <i className="bx bx-briefcase"></i>
                  <span>{this.props.t('Roles')}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="bx bx-group"></i>
                  <span>{this.props.t('Users')}</span>
                </Link>
              </li> */}

              <li>
                <Link to="/pages-timeline" className=" waves-effect">
                  <i className="bx bx-time-five"></i>
                  <span>{this.props.t('Activity')}</span>
                </Link>
              </li>
              {/*  <li>
                        <Link to="/#" className=" waves-effect">
                            <i className="bx bx-message-square-detail"></i>
                            <span>{this.props.t('Message Logs')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/#" className=" waves-effect">
                            <i className="bx bx-message-square-x"></i>
                            <span>{this.props.t('Error Logs')}</span>
                        </Link>
                    </li> 
              <li>
                <Link to="/tutorial" className=" waves-effect">
                  <i className="bx bx-book-open"></i>
                  <span>{this.props.t('Tutorial')}</span>
                </Link>
              </li>*/}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))

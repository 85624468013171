import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import { withTranslation } from "react-i18next"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';



import { v4 as uuid } from "uuid"
import imageCompression from 'browser-image-compression';


// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import maintanence from "../../../assets/images/coming-soon.svg"
import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from 'moment';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getTax,
  addNewTax,
  updateTax,
  deleteTax,
  getVehicle,
} from "store/actions"

import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";

class Taxs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_id: "",
      selectedPeriod: "day",
      selectedVehicleName: "All Vehicles",
      selectedVehicle1: 1,
      selectedDate: "",
      selectedTS: null,
      selectedOption: "todo",
      modal1: false,
      currentYear: new Date().getFullYear(),
      ibadl: false,
      loading: true,
      submitLoading: false,
      isShow: false,
      changed: false,
      vcchanged: false,
      wschanged: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      taxAmount: "",
      selectedVehicle: null,
      taxs: [],
      tax: [],
      vehicles: [],
      date_birth: new Date(),

      TaxColumns: [

        {
          dataField: "year",
          text: this.props.t("Year"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.year}
            </Link>
          ),
        },
        {
          dataField: "vehicle",
          text: this.props.t("Vehicle"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.vehicle}
            </Link>
          ),
        },
        {
          dataField: "createdAt",
          text: this.props.t("Date created"),
          sort: true,
          formatter: (cellContent, row) => (
            <>

              {" "}
              {moment(row.createdAt).format('YYYY-MM-DDTHH:mm')}
            </>
          ),
        },
        {
          dataField: "date",
          text: this.props.t("Payment date"),
          sort: true,
        },

        {
          dataField: "date_next",
          text: this.props.t("Next Payment"),
          sort: true,
          formatter: (cellContent, row) => (

            <>
              <Link to="#" className={Moment(row.date_next).diff(Moment(new Date()), 'days') >= 30 ? "text-success" : (Moment(row.date_next).diff(Moment(new Date()), 'days') > 0 ? "text-warning" : "text-danger")}>
                <i className="far fa-clock me-1" />
              </Link>{" "}

              {row.date_next}
            </>
          ),
        },

        {
          dataField: "amount",
          text: this.props.t("Amount"),
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {" "}
              {row.amount} dh
            </>
          ),
        },
        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("View Details"),
          sort: true,
          formatter: (cellContent, tax) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => this.handleViewClick(tax)}
            >
              {this.props.t("View Details")}
            </Button>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, tax) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleTaxClick(tax)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: tax })} />
                </Link>
              </div>
            </>
          ),
        },
      ],
      selectedFiles: []
    }

    this.handleTaxClick = this.handleTaxClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidTaxSubmit = this.handleValidTaxSubmit.bind(this)
    this.handleTaxClicks = this.handleTaxClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)
    this.handleSelectVehicle = this.handleSelectVehicle.bind(this)
    this.calculateTax = this.calculateTax.bind(this)

    this.toggle_modal = this.toggle_modal.bind(this)
    this.onValueChange = this.onValueChange.bind(this)
    this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
    this.handleSelectVehicleFilter = this.handleSelectVehicleFilter.bind(this)
  }

  onValueChange(event) {
    const { notifs } = this.state
    this.setState({
      selectedOption: event.target.id
    });
    ////console.log(event.target.id)

  }
  dateChanged = d => {
    /*  const { vehicles, rdchanged, RD } = this.state
     this.setState({ PD: d.target.value })*/
    this.setState({
      selectedTS: moment(d.target.value, 'YYYY-MM-DD').valueOf(),
      selectedDate: d.target.value,
      selectedOption: "dia"
    })

    //console.log(d.target.value)
  }
  monthChanged = d => {

    this.setState({
      selectedTS: moment(d.target.value, 'YYYY-MM').valueOf(),
      selectedDate: d.target.value,
      selectedOption: "mes"
    })

    //console.log(d.target.value)
  }
  toggle_modal() {
    this.setState(prevState => ({
      modal1: !prevState.modal1,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  handlePeriodChange = (e) => {
    this.setState({
      selectedPeriod: e.target.value
    })
    //console.log(e.target.value);
  };
  handleValidEventSubmit = (e, values) => {

    //console.log(values)
    if (values.period === "day") {
      this.setState({
        selectedTS: moment(values.date, 'YYYY-MM-DD').valueOf(),
        selectedOption: "dia",
        selectedDate: values.date,
      })
    }
    if (values.period === "month") {
      this.setState({
        selectedTS: moment(values.month, 'YYYY-MM').valueOf(),
        selectedOption: "mes",
        selectedDate: values.month,
      })
    }
    if (values.period === "year") {
      this.setState({
        selectedTS: moment(values.year, 'YYYY').valueOf(),
        selectedOption: "ano",
        selectedDate: values.year,
      })
    }

    this.toggle_modal()
  }
  handleSelectVehicleFilter = (selectedVehicle, value) => {
    const { vehicles } = this.state
    /*  let kra = selectedVehicle.target.value
     let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")")) */
    //console.log(value)
    if (value === 'All vehicles') {
      this.setState({

        selectedVehicleName: "All Vehicles",
        selectedVehicle1: 1
      })
    } else {
      const p = vehicles.find(v => v.id === value);
      var plt = ""
      if (p !== undefined) {
        plt = p.plate_number
      }
      let m = 0

      const name = p.make + " " + p.model + " ( " + p.plate_number + " )"
      //console.log(name)
      vehicles.map((e, key) => {

        if (e.plate_number === plt) {
          m = e.mileage
        }
      })
      this.setState({
        mile: m,
        selectedVehicleName: name,
        selectedVehicle1: value
      })
    }

  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  toLowerCase1(str) {
    return str.toLowerCase();
  }

  componentDidMount() {
    const { taxs, onGetTax } = this.state
    if (taxs && !taxs.length) {
      // onGetTax()
      this.getTax()
    }
    this.setState({ taxs })
    const { vehicles, onGetVehicle } = this.state
    if (vehicles && !vehicles.length) {
      //onGetVehicle()
      this.getVehicle()
    }
    this.setState({ vehicles })


  }
  getTax = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeTaxs) {
      this.unsubscribeTaxs();
    }

    this.unsubscribeTaxs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userTaxsCollectionRef = usersCollection.doc(userId).collection("taxs");
            this.setState({
              user_id: userId,
              account_user: userDoc.data()

            })
            this.unsubscribeTaxs = userTaxsCollectionRef.onSnapshot(snapshot => {
              let t = [];
              snapshot.docs.forEach(doc => {
                t.push(doc.data());
              });

              this.setState({
                loading: false,
                taxs: t
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              taxs: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          taxs: []
        });
      }
    });

  }

  getVehicle = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeVehicles) {
      this.unsubscribeVehicles();
    }

    this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles");
            this.setState({
              user_id: userId
            })
            this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
              let vehicles = [];
              snapshot.docs.forEach(doc => {
                vehicles.push(doc.data());
              });

              this.setState({
                vehicles: vehicles
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              vehicles: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          vehicles: []
        });
      }
    });
  }


  // eslint-disable-next-line no-unused-vars
  /* componentDidUpdate(prevProps, prevState, snapshot) {
    const { taxs } = this.state

    if (!isEmpty(taxs) && size(prevProps.taxs) !== size(taxs)) {
      // this.setState({ taxs: {}, isEdit: false })
    }
  } */

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      ibadl: false,
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  handleTaxClicks = () => {
    this.setState({ file1: null, file2: null, tax: [], isEdit: false, isShow: false, selectedVehicle: null, taxAmount: "" })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { taxs } = this.props
    this.setState({
      taxs: taxs.filter(tax =>
        Object.keys(tax).some(
          key =>
            typeof tax[key] === "string" &&
            tax[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }

  handleSelectVehicle = (selectedVehicle, value) => {
    const { vehicles } = this.state
    this.setState({ selectedVehicle: selectedVehicle })
    this.setState({ vcchanged: true })
    const p = vehicles.find(v => v.id === value);
    var plt = ""
    if (p !== undefined) {
      plt = p.plate_number
    }
    //console.log("sv", plt)
    this.calculateTax(plt)
  }

  calculateTax = vehicle => {

    const { vehicles } = this.state
    const myObj = vehicles.find(obj => obj.plate_number === vehicle);
    //console.log(myObj.fuel_type, myObj.fiscal_power)
    let tax = 0
    if (myObj.fuel_type === "Gasoline") {
      if (myObj.fiscal_power === "1 - 7 cv") {
        tax = 350
      }
      if (myObj.fiscal_power === "8- 10 cv") {
        tax = 650
      }
      if (myObj.fiscal_power === "11 - 14 cv") {
        tax = 3000
      }
      if (myObj.fiscal_power === "+15 cv") {
        tax = 8000
      }
    }
    if (myObj.fuel_type === "Diesel") {
      if (myObj.fiscal_power === "1 - 7 cv") {
        tax = 700
      }
      if (myObj.fiscal_power === "8- 10 cv") {
        tax = 1500
      }
      if (myObj.fiscal_power === "11 - 14 cv") {
        tax = 6000
      }
      if (myObj.fiscal_power === "+15 cv") {
        tax = 20000
      }
    }
    if (myObj.fuel_type === "Hybrid" || myObj.fuel_type === "Electric") {
      tax = 0
    }
    this.setState({ taxAmount: tax })
//console.log("tax amount : ",tax)

  }
  /* Insert,Update Delete data */

  handleDeleteTax = (tax) => {


    /*  const { onDeleteTax } = this.props
     if (tax['code'] === '') {
       //
     } else {
       onDeleteTax(tax) */
    this.deleteTax(tax)
    this.toggleDelete()
    //}

  }
  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data:timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }
  handleViewClick = arg => {
    const tax = arg
    this.setState({ selectedVehicle: { label: tax.vehicle, value: tax.plate_number } })
    this.setState({
      tax: {
        //code: tax.code,
        vehicle: tax.vehicle,
        plate_number: tax.plate_number,
        date: tax.date,
        date_next: tax.date_next,
        amount: tax.amount,
        year: tax.year,
        notes: tax.notes,
        id_file: tax.id_file,
        id_file1: tax.id_file1,
        url: tax.url,
        url1: tax.url1
      },

      isShow: true,
      isEdit: false

    })

    //this.toggleIsShow()
    this.toggle()

    //this.toggleViewModal()
  }


  handleTaxClick = arg => {
    const tax = arg

    this.setState({ selectedVehicle: { label: tax.vehicle, value: tax.plate_number } })
    this.setState({
      tax: {
        id: tax.id,
        vehicle: tax.vehicle,
        plate_number: tax.plate_number,
        date: tax.date,
        date_next: tax.date_next,
        amount: tax.amount,
        year: tax.year,
        notes: tax.notes,
        id_file: tax.id_file,
        id_file1: tax.id_file1,
        url: tax.url,
        url1: tax.url1
      },
      isEdit: true,
      isShow: false,
      file1: null,
      file2: null,
    })

    this.toggle()
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidTaxSubmit = (e, values) => {
    const { onAddNewTax, onUpdateTax } = this.props
    const { isEdit, taxs, selectedTax, vehicles } = this.state







  
    
    /*  const cds = []
     this.props.taxs.map(spl =>
       cds.push(spl.code)
     )
     const cd =cds.length==0?1:Math.max.apply(null,cds)+1 */

    /* let veh="" 
    if(this.state.selectedVehicle!=null){
     veh= this.state.selectedVehicle.label
    if(this.state.vcchanged){
      veh= this.state.selectedVehicle.label
      this.setState({vcchanged:false})
    }else{
      veh=this.state.selectedVehicle.label
    }
  } */


    const p = vehicles.find(v => v.id === values.vehicle);
    var plt = ""
    var vehicle = ""
    if (p !== undefined) {
      plt = p.plate_number
      vehicle = p.make + " (" + p.plate_number + ")"
    }

    /* if(this.state.selectedVehicle!=null){
      plt=this.state.selectedVehicle.value
    if(this.state.vcchanged){
      plt= this.state.selectedVehicle.value
      this.setState({vcchanged:false})
    }else{
      plt=this.state.selectedVehicle.value
    }
  } */

    /* this.setState({
      gender:this.state.staffs.gender
    }) */
    var year = new Date(values.date).getFullYear();
    var month = new Date(values.date).getMonth();
    var day = new Date(values.date).getDate();
    var dt = new Date(year + 1, month, day);
    var next_p = Moment(dt).format('YYYY-MM-DD')
    //console.log(next_p)

    
    var ed = new Date(next_p).getTime()
    const now = new Date().getTime();
    const fifteen = ed - 15 * 24 * 60 * 60 * 1000;
//console.log(ed,now,fifteen)
    
var pd = new Date(values.date).getTime()


    var am = parseInt(values.amount)
    if (isEdit) {
      //des=this.state.selectedType.defaultInputValue

      const updateTax = {

        id: this.state.tax.id,/* 
        vehicle: values.vehicle.trim(),
        plate_number: plt, */
        vehicle:this.state.tax.vehicle,
        ed:ed,
        fifteen:fifteen,
        v_id: values.vehicle,
        date: values.date.trim(),
        pd:pd,
        date_next: next_p,
        amount: am,
        year: values.year.trim(),
        notes: values.notes.trim(),
        id_file: this.state.tax.id_file,
        id_file1: this.state.tax.id_file1,
        url: this.state.tax.url,
        url1: this.state.tax.url1

      }

      // update Order
      //onUpdateTax(updateTax)
      if (!this.state.ibadl &&/* 
        this.state.tax.vehicle == updateTax.vehicle && */
        this.state.tax.date == updateTax.date &&
        this.state.tax.amount == updateTax.amount &&
        this.state.tax.year == updateTax.year &&
        this.state.tax.notes == updateTax.notes) {
        this.setState({ submitLoading: false })
        this.toggle()
      } else {
        this.updateTax(updateTax)
      }


    } else {

      const newTax = {

        vehicle: vehicle,
        plate_number: plt,
        v_id: values.vehicle,
        date: values["date"],
        pd:pd,
        date_next: next_p,
        amount: am,
        ed:ed,
        fifteen:fifteen,
        year: values["year"],
        notes: values["notes"],
      }
      ////console.log(des)
      // save new Order
      //onAddNewTax(newTax)
      this.setState({ tax: newTax })
      this.addNewTax(newTax)

    }
    this.setState({ selectedTax: null })
    //this.toggle()
  }

  deleteTax = (tax) => {

    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("taxs")
    const timeline = {
      statusTitle: "Annual tax deleted",
      iconClass: "bx bx-receipt h2 text-danger",
      description: "Annual tax information has been deleted for " ,
      data:tax.vehicle,
      time: new Date().getTime(),

    }
    collection.doc(tax.id).delete().then(() => {

      //console.log("Element  deleted!");
      toast.success(this.props.t("Tax  deleted!"))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Element has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"))

    });
  }

  updateTax = async (tax) => {

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("taxs")
    const timeline = {
      statusTitle: "Annual tax updated",
      iconClass: "bx bx-receipt h2 text-warning",
      description: "Annual tax information has been updated for "  ,
      data:tax.vehicle,
      time: new Date().getTime(),

    }
    collection.doc(tax.id).update({
      /* vehicle: tax.vehicle,
      plate_number: tax.plate_number, */
      date: tax.date,
      pd:tax.pd,
      date_next: tax.date_next,
      amount: tax.amount,
      year: tax.year,
      notes: tax.notes,
      ed:tax.ed,
        fifteen:tax.fifteen,

    }).then(() => {
      //console.log("Document Updated!");
      toast.success(this.props.t("Document Updated!"))
      this.addNewTimeline(timeline)
      this.setState({ submitLoading: false })
      this.toggle()
    }).catch((error) => {
      console.error("Error updating document: ", error);
      toast.error(this.props.t("Error updating document"))
      this.setState({ submitLoading: false })
    });

  }

  addNewTax = async (tax) => {
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("taxs")
    const timeline = {
      statusTitle: "Annual tax added",
      iconClass: "bx bx-receipt h2 text-success",
      description: "Annual tax information has been added " ,
      data:tax.vehicle,
      time: new Date().getTime(),

    }
    this.setState({ submitLoading: true })

    var newDoc = collection.doc();
    var doc_id = newDoc.id
    newDoc.set(
      {
        id: newDoc.id,
        vehicle: tax.vehicle,
        plate_number: tax.plate_number,
        v_id: tax.v_id,
        date: tax.date,
        pd:tax.pd,
        date_next: tax.date_next,
        ed:tax.ed,
        fifteen:tax.fifteen,
        amount: tax.amount,
        year: tax.year,
        notes: tax.notes,
        createdAt: new Date().getTime()

      }).then(() => {


        //console.log("tax  written!");
        toast.success(this.props.t('Tax added '))
        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()


      })
      .catch((error) => {
        //console.log("Error getting documents " + error);
        toast.error(this.props.t("Error Adding Tax infos"))
        this.setState({ submitLoading: false })
      });



  }


  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }


  render() {

    const emptyDataMessage = () => {
      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your Annual tax infos by clicking ")}<h5 className="text-primary"><Link onClick={this.handleTaxClicks} >{this.props.t("New Annual tax")}</Link></h5>
                </p>
              </div>
            }
          </Col>
        </Row>
      </Container>


        ;
    }
    const { currentYear, selectedVehicle1, selectedOption, selectedTS, selectedDate, selectedPeriod, taxs } = this.state
    var tx
    tx = taxs
    const data = tx;
    //console.log(selectedOption)
    switch (selectedOption) {
      case 'todo':
        var t = []
        taxs.map(function (item) {
          if (selectedVehicle1 === 1) {
            t.push(item)
          }
          if (item.v_id === selectedVehicle1) {
            t.push(item)
          }
        });

        tx = t
        break;
      case 'hoy':
        var ta = []
        taxs.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DD')
          var pd = new Date(start).getTime()

          var sd = moment(new Date()).format('MM-DD-YYYY')
          var ds = moment(pd).format("MM-DD-YYYY");
          var t = moment(ds)
          var s = moment(sd)
          //console.log(ds)
          if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
            ta.push(item)
          }
          if (s.isSame(t, 'day') && item.v_id === selectedVehicle1) {
            ta.push(item)
          }
        });

        tx = ta
        break;
      case 'sempa':
        var t = []
        taxs.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DD')
          var pd = new Date(start).getTime()
          if (moment(new Date().getTime()).isSame(moment(pd), 'week') && selectedVehicle1 === 1) {
            t.push(item)
          }
          if (moment(new Date().getTime()).isSame(moment(pd), 'week') && item.v_id === selectedVehicle1) {
            t.push(item)
          }
        });

        tx = t
        break;
      case 'mespa':
        var t = []
        taxs.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DD')
          var pd = new Date(start).getTime()
          if (moment(new Date().getTime()).isSame(moment(pd), 'month') && selectedVehicle1 === 1) {
            t.push(item)
          }
          if (moment(new Date().getTime()).isSame(moment(pd), 'month') && item.v_id === selectedVehicle1) {
            t.push(item)
          }
        });


        tx = t
        break;
      case 'dia':
        //console.log("diaaaaa")
        var ta = []
        taxs.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DD')
          var pd = new Date(start).getTime()
          var sd = moment(selectedTS).format('MM-DD-YYYY')
          var ds = moment(pd).format("MM-DD-YYYY");
          var t = moment(ds)
          var s = moment(sd)
          if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
            ta.push(item)
          }
          if (s.isSame(t, 'day') && item.v_id === selectedVehicle1) {
            ta.push(item)
          }
        });
        tx = ta
        break;
      case 'mes':
        //console.log("meeeees")
        var t = []
        taxs.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DD')
          var pd = new Date(start).getTime()
          if (moment(selectedTS).isSame(moment(pd), 'month') && selectedVehicle1 === 1) {
            t.push(item)
          }
          if (moment(selectedTS).isSame(moment(pd), 'month') && item.v_id === selectedVehicle1) {
            t.push(item)
          }
        });

        tx = t
        break;
      case 'ano':
        //console.log("anooooo")
        var t = []
        taxs.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DD')
          var pd = new Date(start).getTime()
          if (moment(selectedTS).isSame(moment(pd), 'year') && selectedVehicle1 === 1) {
            t.push(item)
          }
          if (moment(selectedTS).isSame(moment(pd), 'year') && item.v_id === selectedVehicle1) {
            t.push(item)
          }
        });


        tx = t
        break;
      default:

    }

    let dataxcl = []
    tx.map(function (item) {
      delete item.tax;
      dataxcl.push({
        vehicle: item.vehicle,
        plate_number: item.plate_number,
        date: item.date,
        date_next: item.date_next,
        amount: item.amount,
        year: item.year,
        notes: item.notes,
      })
    });
    const { SearchBar } = Search
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc } = this.state
    const { vehicles } = this.state
    const { selectedVehicle } = this.state


    //pagination customization
    const vcList = []
    vcList.push({ label: "", value: "" })
    vehicles.map(function (item, i) {
      vcList.push({ id: item.id, label: item.make + ' (' + item.plate_number + ')', value: item.plate_number })

    })
    const vcList1 = []
    vcList1.push({ label: "All vehicles", value: "All" })
    vehicles.map(function (item, i) {

      vcList1.push({ id: item.id, label: item.make + "(" + item.plate_number + ")", value: item.plate_number })

    })


    const defaultSorted = [{
      dataField: 'createdAt',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };



    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: 'Annual tax', exportType: exportFromJSON.types.xls })
    }
    function exportPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Vignettes";
      const headers = [["Vehicle", "Date payement", "Next payment", "Year", "Amount", "Notes"]];

      const dt = dataxcl.map(elt => [elt.vehicle, elt.date, elt.date_next, elt.year, elt.amount + 'Dh', elt.notes]);

      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      doc.save("Annual tax.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Annual tax";
      const headers = [["Vehicle", "Date payement", "Next payment", "Year", "Amount", "Notes"]];

      const dt = dataxcl.map(elt => [elt.vehicle, elt.date, elt.date_next, elt.year, elt.amount + 'Dh', elt.notes]);


      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }

    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Annual tax | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Fleet")} breadcrumbItem={this.props.t("Annual tax")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row className="mb-2" >

              <Row>

                <Col dm="3" className="text-sm-center mb-2">
                  <AvForm>
                    <AvField
                      name="title"
                      type="select"
                      onChange={this.handleSelectVehicleFilter}
                      validate={{
                        required: { value: true },
                      }}

                    >
                      {vcList1.map((e, key) => {

                        return <option key={key} value={e.id}>{e.label}</option>;

                      })}</AvField>
                  </AvForm>



                </Col>
                <Col md="6" >
                  <div className="text-end " >

                    <Col   >

                      <div className="btn-group me-2" role="group" aria-label="Basic radio toggle button group" onChange={this.onChangeValue}>
                        <input type="radio" className="btn-check" name="btnradio" id="todo" onClick={this.onValueChange} autoComplete="off" defaultChecked />
                        <label className="btn btn-primary" htmlFor="todo">{this.props.t("All")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="hoy" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="hoy">{this.props.t("Today")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="sempa" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="sempa">{this.props.t("This week")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="mespa" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="mespa">{this.props.t("This month")}</label>

                        <button
                          type="button"
                          className="btn btn-light mb-2"
                          onClick={this.toggle_modal}
                        >
                          <i className="bx bx-filter  font-size-22 align-middle "></i>

                        </button>
                      </div>

                    </Col>

                  </div>
                </Col>


                <Modal
                  isOpen={this.state.modal1}
                  toggle={this.toggle_modal}
                  id="event-modal"
                >
                  <ModalHeader toggle={this.toggle_modal} tag="h4">
                    {this.props.t("Custom Period")}
                  </ModalHeader>
                  <ModalBody>
                    <AvForm onValidSubmit={this.handleValidEventSubmit}>
                      <Row form>
                        <Col className="col-12 mb-3">

                          {/* <AvField
name="title"
label="Vehicles"
type="select"
onChange={this.handleSelectVehicle}
validate={{
required: { value: true },
}}

>
{vcList.map((e, key) => {

return <option key={key} value={e.id}>{e.label}</option>;

})}</AvField> */}
                        </Col>
                        <Col className="col-12 mb-3">
                          <AvField
                            type="select"
                            name="period"
                            label={this.props.t("Select Period")}
                            defaultValue={selectedPeriod}
                            validate={{
                              required: { value: true },
                            }}
                            onChange={this.handlePeriodChange}
                          >
                            <option value="day">{this.props.t("Day")}</option>
                            <option value="month">{this.props.t("Month")}</option>
                            <option value="year">{this.props.t("Year")}</option>
                          </AvField>
                        </Col>

                        {selectedPeriod === "day" ?
                          <div className="mb-3 mt-4 row">
                            <label
                              htmlFor="example-month-input"
                              className="col-md-2 col-form-label"
                            >
                              {this.props.t("Day")}
                            </label>

                            <div className="col">
                              <AvField
                                className="form-control text-sm-start"
                                name="date"
                                type="date"
                                onChange={this.dateChanged}
                                id="example-date-input"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </div>
                          : null}
                        {selectedPeriod === "month" ?
                          <div className="mb-6 row mb-3">
                            <label
                              htmlFor="example-month-input"
                              className="col-md-2 col-form-label"
                            >
                              {this.props.t("Month")}
                            </label>
                            <div className="col">
                              <AvField
                                name="month"
                                className="form-control"
                                type="month"
                                onChange={this.monthChanged}
                                id="example-month-input"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </div>
                          : null}
                        {selectedPeriod === "year" ?
                          <div className="mb-3 row mb-3">
                            <AvField
                              type="select"
                              name="year"
                              label="Select year"
                              defaultValue={currentYear}
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value={currentYear}>{currentYear}</option>
                              <option value={currentYear - 1}>{currentYear - 1}</option>
                              <option value={currentYear - 2}>{currentYear - 2}</option>
                              <option value={currentYear - 3}>{currentYear - 3}</option>
                              <option value={currentYear - 4}>{currentYear - 4}</option>
                            </AvField>
                          </div>
                          : null}
                      </Row>
                      {/* <h5 className="text-center mt-4">
{this.state.selectedDate || this.state.selectedMonth}
</h5> */}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          data-dismiss="modal"
                          onClick={this.toggle_modal}
                        >
                          {this.props.t("Close")}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success"
                        >
                          {this.props.t("Confirm")}
                        </button>
                      </div>
                    </AvForm>
                  </ModalBody>


                </Modal>

              </Row>

            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.TaxColumns || [])}
                      data={(tx || [])}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={tx}
                          columns={(this.state.TaxColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" class="noPrint">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleTaxClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      {this.props.t("New Annual tax")}
                                    </Button>

                                    <Link to="#"

                                    >

                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-18" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => tx.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => tx.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => tx.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                                <Modal
                                  isOpen={this.state.modal}
                                  className={this.props.className}
                                >
                                  <ModalHeader toggle={this.toggle} tag="h4">
                                    {!!isEdit ? this.props.t("Edit Annual tax") : null}
                                    {!!this.state.isShow ? this.props.t("Annual tax Infos") : null}
                                    {!isEdit && !this.state.isShow ? this.props.t("Add Annual tax") : null}
                                  </ModalHeader>
                                  <ModalBody>
                                    {this.state.submitLoading ?
                                      <Col>
                                        <div>
                                          <div >
                                            <div className="spinner-chase">
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                      </Col>

                                      :

                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidTaxSubmit
                                        }
                                        disabled={this.state.isShow ? true : false}
                                      >
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <div className="mb-3 select2-container">
                                                <Label >{this.props.t("Vehicle")}</Label>
                                                {!isEdit && !this.state.isShow ?
                                                  <div>
                                                    <AvField
                                                      name="vehicle"
                                                      type="select"
                                                      placeholder={""}
                                                      onChange={this.handleSelectVehicle}
                                                      //options={vcList}
                                                      value={this.state.tax.vehicle}
                                                      disabled={this.state.isShow || !!isEdit ? true : false}
                                                      errorMessage={this.props.t("Select a Vehicle")}
                                                      className="form-control"

                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                    >

                                                      {vcList.map((e, key) => {
                                                        if (key == 0) {
                                                          return <option key={key} value="" disabled>{this.props.t("vehicle")}</option>;
                                                        } else {
                                                          return <option key={key} value={e.id}>{e.label}</option>;
                                                        }
                                                      })}
                                                    </AvField>
                                                    <Link to="/vehicles" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Vehicle")}</Link>
                                                  </div>
                                                  :
                                                  <AvField
                                                    name="vehicle"
                                                    type="text"
                                                    disabled={this.state.isShow || !!isEdit ? true : false}
                                                    value={this.state.tax.vehicle || ""}
                                                  />
                                                }                        </div>
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">

                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                {this.props.t("Year")}
                                              </Label>
                                              <AvField
                                                name="year"
                                                placeholder={this.props.t("Year")}
                                                type="number"
                                                errorMessage={this.props.t("Enter Year")}
                                                value={this.state.tax.year || ""}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="6">

                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                {this.props.t("Amount")}
                                              </Label>
                                              <AvField
                                                name="amount"
                                                placeholder={/* this.state.taxAmount ||  */this.props.t("Amount")}
                                                type="number"
                                                errorMessage={this.props.t("Enter Amount")}
                                                value={isEdit || this.state.isShow ? this.state.tax.amount+""  : this.state.taxAmount+""}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-4">
                                              <Label>{this.props.t("Payment date")}</Label>
                                              <AvField
                                                name="date"
                                                placeholder={this.props.t("Payment date")}
                                                type="date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={Moment(this.state.tax.date).format('YYYY-MM-DD') || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>

                                        <Row>

                                          <Col md="12" >
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="notes"
                                                label={this.props.t("Notes")}
                                                placeholder={this.props.t("Notes")}
                                                type="textarea"
                                                rows="2"
                                                value={this.state.tax.notes || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>


                                        {this.state.isShow ? null :
                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  {this.props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        }

                                      </AvForm>
                                    }
                                  </ModalBody>
                                </Modal>


                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteTax(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}

                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}
                              </div>

                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Taxs.propTypes = {
  taxs: PropTypes.array,
  vehicles: PropTypes.array,
  onGetTax: PropTypes.func,
  onAddNewTax: PropTypes.func,
  onDeleteTax: PropTypes.func,
  onUpdateTax: PropTypes.func,
  onGetVehicle: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  taxs: state.vignette.taxs,
  vehicles: state.vehicle.vehicles,
})

const mapDispatchToProps = dispatch => ({
  onGetTax: () => dispatch(getTax()),
  onGetVehicle: () => dispatch(getVehicle()),
  onAddNewTax: tax => dispatch(addNewTax(tax)),
  onUpdateTax: tax => dispatch(updateTax(tax)),
  onDeleteTax: tax => dispatch(deleteTax(tax)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Taxs)))